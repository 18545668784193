import "./style.scss";
import { Layout } from 'antd';
import { Images } from "images";
const AntHeader = Layout.Header

const divStyle = {
    paddingTop: 20,
    paddingBottom: 60,
    display: 'flex',
}

export const Header = () => {
    return (
        <AntHeader
            breakpoint={"lg"}
            style={divStyle}
        >
            <div>
                <img
                    className={"logo"}
                    src={Images.stoquemarketLogo}
                    alt={"stoque-logo"}
                />
                <img
                    className={"mobile-logo"}
                    src={Images.stoquemarketLogoSmall}
                    alt={"stoque-logo"}
                />
            </div>

        </AntHeader >
    );
}