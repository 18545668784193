import { combineReducers } from "redux";
import { resettableReducer } from "reduxsauce";
import { CommonTypes } from "./common";
import { AuthReducers, AuthSagas } from "./auth";
import { RestaurantsReducers, RestaurantsSagas } from "./restaurants";
import { SuppliersReducers, SuppliersSagas } from "./suppliers";
import { TagsReducers, TagsSagas } from "./tags";
import { SpecsReducers, SpecsSagas } from "./specs";
import { CategoriesReducers, CategoriesSagas } from "./categories";
import { MappingSuppliersReducers, MappingSuppliersSagas } from "./supplier_mapping";
import { MutualisationsReducers, MutualisationsSagas } from "./mutualisations";
import { FacturationReducers, FacturationSagas} from "./facturation";


// Specify reset action type rule
const resettable = resettableReducer(CommonTypes.RESET_REDUCERS);

// Merge our redux together
const reducers = {
    auth: resettable(AuthReducers),
    restaurants: resettable(RestaurantsReducers),
    suppliers: resettable(SuppliersReducers),
    tags: resettable(TagsReducers),
    specs: resettable(SpecsReducers),
    categories: resettable(CategoriesReducers),
    smapping: resettable(MappingSuppliersReducers),
    mutualisations: resettable(MutualisationsReducers),
    facturation: resettable(FacturationReducers)
};

export const rootSagas = [
    ...AuthSagas,
    ...RestaurantsSagas,
    ...SuppliersSagas,
    ...TagsSagas,
    ...SpecsSagas,
    ...CategoriesSagas,
    ...MappingSuppliersSagas,
    ...MutualisationsSagas,
    ...FacturationSagas
];

export const rootReducers = combineReducers(reducers);