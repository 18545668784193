import React, { useState, useRef } from 'react'
import { useDispatch } from "react-redux";
import { AuthActions } from "stores/auth";
import { Images } from "images";
import { validEmailRegex } from "utils/regex";
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Form, Input } from 'antd';
import { t } from "assets"
import { Layout } from "antd";
import './style.scss';

const { Content } = Layout;

const INITIAL_FORM = {
    email: "",
    password: ""
};

const INPUTS = [
    {
        key: "email",
        label: t("login.emailPlaceholder"),
        placeholder: t("login.emailPlaceholder"),
    },
    {
        key: "password",
        label: t("login.passwordLabel"),
        placeholder: t("login.passwordPlaceholder"),
        type: "password"
    }
];

const errorMessage = t("error.errorFieldMessage");



export const Login = ({ history }) => {


    const [data, setData] = useState(INITIAL_FORM);
    const [errors, setErrors] = useState({});
    const passwordRef = useRef({});
    const dispatch = useDispatch();

    const onFormChange = (key, value) => {
        setData({ ...data, [key]: value });
        setErrors({ ...errors, [key]: validateField(key, value) });
    };
    const onFormSubmit = e => {
        const keys = Object.keys(data);

        const newErrors = { ...errors };
        let hasError = false;
        keys.length &&
            keys.forEach(key => {
                const currentError = validateField(key, data[key]);
                newErrors[key] = currentError;
                if (currentError && currentError.length) hasError = true;
            });
        setErrors(newErrors);
        if (hasError) return;
        dispatch(AuthActions.loginRequest(data.email, data.password, history))


    };

    const validateField = (key, value) => {
        if (!value) return errorMessage;
        switch (key) {
            case "email":
                return validEmailRegex.test(value) ? "" : errorMessage;
            default:
                return "";
        }
    };

    return (
        <React.Fragment>
            <Content>
                <div className="signin">
                    <div className="signin-form">
                        <img src={Images.stoquemarketSignin} alt="stoquemarket" />
                        <h4>{t("login.title")}</h4>
                        <Form name="normal_login"
                            className="login-form form" onFinish={onFormSubmit}>
                            {INPUTS.map(input => {
                                const { key, label, placeholder } = input;
                                return (
                                    <div key={key}>
                                        {input.type !== "password" ? (
                                            <Form.Item
                                                labelCol={{ span: 24 }}
                                                rules={[{ required: true, message: t("login.error.email"), pattern: validEmailRegex }]}
                                                name={label}
                                                label={label}
                                                placeholder={placeholder}
                                                value={data[key]}
                                                onChange={e => onFormChange(key, e?.target?.value)}
                                                error={errors[key]}
                                                type="text"
                                                required={true}
                                            >
                                                <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder={t("login.emailLabel")} />
                                            </Form.Item>
                                        ) : (
                                            <Form.Item
                                                labelCol={{ span: 24 }}
                                                name={label}
                                                label={label}
                                                rules={[{ required: true, message: t("login.error.password") }]}
                                            >
                                                <Input
                                                    prefix={<LockOutlined className="site-form-item-icon" />}
                                                    type="password"
                                                    ref={passwordRef}
                                                    label={label}
                                                    placeholder={t("login.passwordLabel")}
                                                    onChange={e => onFormChange(key, e?.target?.value)}
                                                />
                                            </Form.Item>
                                        )}
                                    </div>
                                );
                            })}
                            <Button type="primary" htmlType="submit">
                                {t("login.connection")}
                            </Button>
                        </Form>
                    </div>
                </div>
            </Content>
        </React.Fragment>
    )
}
