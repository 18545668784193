import { createAction } from '../../utils/redux-helper';

// Types
export const types = {
  RESET_REDUCERS: 'common_RESET_REDUCERS',
};

// Actions
export default {
  resetAllReducers: () => createAction(types.RESET_REDUCERS),
};