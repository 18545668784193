import { takeLatest, call, put, select } from "redux-saga/effects";
import { SupplierServices } from "../../services";
import { default as SuppliersActions, types } from "./actions";
import { showError, showSuccess } from "utils/helpers/notifications";
import { get } from "lodash";

//exported sagas
function* getSuppliers() {
    yield takeLatest(types.GET_SUPPLIERS_REQUEST, getSuppliersSaga);
}

function* getSuppliersNames() {
    yield takeLatest(types.GET_SUPPLIERS_NAMES_REQUEST, getSuppliersNamesSaga);
}

function* exportProducts() {
    yield takeLatest(types.EXPORT_PRODUCTS_REQUEST, exportProductsSaga);
}

//logical sagas

function* getSuppliersNamesSaga() {
    const [error, response] = yield call(SupplierServices.getSuppliersNames);
    if (!error && response) {
        const suppliers = get(response, ["supplier_informations"], []);
        yield put(SuppliersActions.getSuppliersSuccess(suppliers, -1));
    } else {
        yield put(SuppliersActions.getSuppliersFailure());
        showError("accountsTable.errors.fetchingData");
    }
}

function* getSuppliersSaga(action) {
    let filter = { users: {} };
    if (action.searchValue.length > 0) {
        filter = {
            users: {},
            name: { _ilike: `%${action.searchValue}%` },
        };
    }
    const [error, response] = yield call(
        SupplierServices.getSuppliers,
        action.offset,
        action.limit,
        filter
    );

    if (!error && response) {
        const suppliers = get(response, ["supplier_informations"], []);
        const count = get(
            response,
            ["supplier_informations_aggregate", "aggregate", "count"],
            0
        );
        yield put(SuppliersActions.getSuppliersSuccess(suppliers, count));
    } else {
        yield put(SuppliersActions.getSuppliersFailure());
        showError("accountsTable.errors.fetchingData");
    }
}

function* validatePayment() {
    yield takeLatest(types.VALIDATE_PAYMENT_REQUEST, validatePaymentSaga);
}

function* validatePaymentSaga(action) {
    const [error, response] = yield call(
        SupplierServices.validatePayment,
        action.id
    );
    if (!error && response) {
        yield put(SuppliersActions.validatePaymentSuccess(action.key));
        showSuccess("accountsTable.success.validatePayment");
    } else {
        yield put(SuppliersActions.validatePaymentFailure());
        showError("accountsTable.errors.validatePayment");
    }
}

function* setStatusRequest() {
    yield takeLatest(types.SET_STATUS_REQUEST, setStatusSaga);
}

function* setStatusSaga(action) {
    const [error, response] = yield call(
        SupplierServices.setStatus,
        action.userId,
        action.status
    );
    if (!error && response) {
        yield put(SuppliersActions.setStatusSuccess(action.key, action.status));
        showSuccess("accountsTable.success.setStatus");
    } else {
        yield put(SuppliersActions.setStatusFailure(action.key, action.status));
        showError("accountsTable.errors.setStatus");
    }
}

function* exportProductsSaga(action) {
    const { token } = yield select((state) => state.auth);
    const response = yield call(SupplierServices.exportProducts, token, action.supplierId);

    yield put(SuppliersActions.exportProductsSuccess(response));
}

export default [
    getSuppliers(),
    validatePayment(),
    setStatusRequest(),
    getSuppliersNames(),
    exportProducts(),
];
