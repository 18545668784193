import React, { useState } from "react";
import {useDispatch, useSelector} from "react-redux";
import {Button, DatePicker, Form} from "antd";
import { t } from "assets";
import {validEmailRegex} from "../../utils/regex";
import './style.scss';
import {FacturationActions, FacturationSelectors} from "../../stores/facturation";
import {Loader} from "../components/loader";


const INITIAL_FORM = {
    date_from: "",
    date_to: ""
};

const INPUTS = [
    {
        key: "date_from",
        label: t("facturation.date_from"),
        placeholder: t("facturation.emailPlaceholder"),
        type: "date"
    },
    {
        key: "date_to",
        label: t("facturation.date_to"),
        placeholder: t("facturation.passwordPlaceholder"),
        type: "date"
    }
];

const errorMessage = t("error.errorFieldMessage");

export const Facturation = () => {


    const [data, setData] = useState(INITIAL_FORM);
    const [errors, setErrors] = useState({});
    const dispatch = useDispatch();
    const loading = useSelector(FacturationSelectors.loading);

    const onFormChange = (key, value) => {
        setData({ ...data, [key]: value });
        setErrors({ ...errors, [key]: validateField(key, value) });
    };
    const onFormSubmit = () => {
        const keys = Object.keys(data);

        const newErrors = { ...errors };
        let hasError = false;
        keys.length &&
        keys.forEach(key => {
            const currentError = validateField(key, data[key]);
            newErrors[key] = currentError;
            if (currentError && currentError.length) hasError = true;
        });
        setErrors(newErrors);
        if (hasError) return;
        dispatch(FacturationActions.getFacturationRequest(data.date_from, data.date_to))
    };

    const validateField = (key, value) => {
        if (!value) return errorMessage;
        switch (key) {
            case "email":
                return validEmailRegex.test(value) ? "" : errorMessage;
            default:
                return "";
        }
    };

    return (
        <React.Fragment>
            <div className={'admin-factu'}>
                <Form name="factu"
                    className="factu-form form" onFinish={onFormSubmit}>
                    {INPUTS.map(input => {
                        const { key, label, placeholder } = input;
                        return (
                            <div key={key}>
                                {input.type === 'date'? (
                                    <Form.Item
                                        labelCol={{ span: 24 }}
                                        name={label}
                                        label={label}
                                        placeholder={placeholder}
                                        value={data[key]}
                                        onChange={e => onFormChange(key, e?.target?.value)}
                                        error={errors[key]}
                                        type="text"
                                        required={true}
                                    >
                                    <DatePicker
                                        format={"DD/MM/YYYY"}
                                        onChange={e => onFormChange(key, e.format("YYYY-MM-DD"))}
                                        value={data[key]}/>
                                    </Form.Item>
                                ) : (<div></div>
                                )}
                            </div>
                        );
                    })}
                    <Button type="primary" htmlType="submit">
                        {t("facturation.download")}
                    </Button>
                    {loading && <Loader className={"loader-factu"} spinning={loading}/>}
                </Form>

            </div>
        </React.Fragment>
    )
}