import { useState, useEffect } from "react";
import { Modal, Input, Button, Select, Form } from "antd";
import { t } from "assets";
import { get } from "lodash";
import "./style.scss";
import { MappingSuppliersActions } from "stores/supplier_mapping";
import { useDispatch } from "react-redux";

const ManageModal = ({
        isModalOpen,
        handleClose,
        categories,
        selectedProduct,
        supplierId = null,
        addProduct = false,
    }) => {
    const [subCategOptions, setsubCategOptions] = useState([]);
    const [categOptions, setcategOptions] = useState([]);

    const [selectedCategory, setSelectedCategory] = useState([]);
    const [selectedSubCategory, setSelectedSubCategory] = useState([]);


    const dispatch = useDispatch();
    const onChangeCategory = (value) => {
        setSelectedCategory(value);
        setSelectedSubCategory([]);
        categOptions.forEach(cat => {
            if (cat.value === value)
                setsubCategOptions(cat.children);
        });
    }

    const onChangeSubCategory = (value) => {
        setSelectedSubCategory(value);
    }

    useEffect(() => {
        const categoryList = [];
        let currentCategory;

        categories.forEach(cat=> {
            let subs = [];
            cat.sub_categories.forEach(sub_cat => {
                let currentSub = {
                    value: sub_cat.id,
                    label: sub_cat.label
                }
                subs.push(currentSub);
            });
            currentCategory = {
                value: cat?.key,
                label: cat?.name,
                children: subs,
            }
            categoryList.push(currentCategory);
        });
        setcategOptions(categoryList);
    }, [categories]);

    const onFormSubmit = (values) => {
        handleClose();
        let data = {
            id: selectedProduct?.id,
            idSubCateg: get(values, "mapping-form-sub_category", ""),
            origin: get(values, "mapping-form-origin", ""),
            extRef: selectedProduct?.external_ref,
            newRef: get(values, "mapping-form-new_ref", ""),
            categoryName: selectedCategory,
            subCategoryName: selectedSubCategory,
            supplierId: supplierId,
        }
        dispatch(
            MappingSuppliersActions
            .upsertSupplierMappingRequest(
                data
            )
        );
    }

    const getModalTitle = () => {
        if (addProduct)
            return t("mappingSuppliersTable.form.newTitle");
        else
            return `${t("mappingSuppliersTable.form.editTitle")} : ${selectedProduct?.external_ref}`;
    }

    return (
        <Modal
            title={getModalTitle()}
            open={isModalOpen}
            onCancel={handleClose} 
            footer={null}
            destroyOnClose={true}
        >
            <div className="categories_selector">
                <Form
                    onFinish={onFormSubmit}
                    name="mapping-product-form"
                    layout="vertical"
                    preserve={false}
                    initialValues={{
                        "mapping-form-origin" : "",
                        "mapping-form-new_ref" : "",
                        "mapping-form-category" : null,
                        "mapping-form-sub_category" : null,
                    }}
                >
                    {addProduct && (
                        <Form.Item
                            name={"mapping-form-new_ref"}
                            label={t("mappingSuppliersTable.form.ref")}
                            rules={[
                                {
                                    required: true,
                                    message: t("mappingSuppliersTable.form.errors.mandatoryRef")
                                },
                            ]}
                        >
                            <Input placeholder={t("mappingSuppliersTable.form.refPlaceholder")}/>
                        </Form.Item>
                    )}
                    <Form.Item
                        name={"mapping-form-category"}
                        label={t("mappingSuppliersTable.form.category")}
                        rules={[
                            {
                                required: true,
                                message: t("mappingSuppliersTable.form.errors.mandatoryCategory")
                            },
                        ]}
                    >
                        <Select
                            onChange={onChangeCategory}
                            options={categOptions}
                            placeholder={t("mappingSuppliersTable.form.categoryPlaceholder")}
                        />
                    </Form.Item>
                    <Form.Item
                        name={"mapping-form-sub_category"}
                        label={t("mappingSuppliersTable.form.subCategory")}
                        rules={[
                            {
                                required: true,
                                message:  t("mappingSuppliersTable.form.errors.mandatorySubCategory")
                            },
                        ]}
                    >
                        <Select
                            onChange={onChangeSubCategory}
                            options={subCategOptions}
                            placeholder={t("mappingSuppliersTable.form.subCategoryPlaceholder")}
                            value={selectedSubCategory}
                            disabled={selectedCategory.length === 0}
                        />
                    </Form.Item>
                    <Form.Item
                        name={"mapping-form-origin"}
                        label={t("mappingSuppliersTable.form.origin")}
                    >
                        <Input placeholder={t("mappingSuppliersTable.form.originPlaceholder")}/>
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            {t("mappingSuppliersTable.form.save")}
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </Modal>
    );
}

export {
    ManageModal
}