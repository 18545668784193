import { createSelector } from "reselect";
import { get } from "lodash";
const STORE_NAME = "categories";

const categories = createSelector(
    state => get(state, [STORE_NAME, "categories"], []),
    categories => {
        return categories.map(category => {
            const id = get(category, ["id"], "");
            const name = get(category, ["label"], "");
            const order = get(category, ["order"], "");
            const sub_categories = get(category, ["sub_categories"], []);
            return ({
                key: id,
                name: name,
                order: order,
                sub_categories: sub_categories,
            });
        });
    }
);

const loading = createSelector(
    state => get(state, [STORE_NAME, "loading"], []),
    loading => {
        return loading;
    }
);

const selectors =  {
    categories,
    loading,
};

export default selectors;