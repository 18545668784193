import { useState, useImperativeHandle, forwardRef } from "react";
import { TagsActions } from "stores/tags";
import { useDispatch } from "react-redux";
import { Modal } from "antd";
import { t } from "assets";
import { get } from "lodash";

const DeleteModalComponent = ({ isModalOpen, setDeleteModal }, ref) => {

    const dispatch = useDispatch();
    const [idTagToDelete, setIdTag] = useState(null);

    useImperativeHandle(ref, () => ({
        showModal(value)  {
            const id = get(value, "key", null);
            setIdTag(id);
            setDeleteModal(true);
        }
    }));

    const handleClose = () => {
        setDeleteModal(false);
    };

    const handleConfirm = () => {
        dispatch(TagsActions.deleteTagRequest(idTagToDelete))
        setDeleteModal(false);
    };

    return (
        <Modal
            title={t("tagsTable.deleteModal.title")}
            open={isModalOpen}
            onCancel={handleClose} 
            onOk={handleConfirm}
            destroyOnClose
        >
            <div>
                <p>{t("tagsTable.deleteModal.confirmDelete")}</p>
            </div>
        </Modal>
    );
};

const DeleteModal = forwardRef(DeleteModalComponent);

export {
    DeleteModal
}