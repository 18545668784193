import { Button, Modal } from "antd";
import { t } from "assets";
import { get } from "lodash";

const DetailsModal = ({ isModalOpen, handleClose, validatePayment, additionalDataKey, restaurants }) => {
    const restauIdx = restaurants.findIndex((item) => item?.key === additionalDataKey);
    const data = get(restaurants, restauIdx, []);
    const additionalData = get(data, "additional_data", []);
    const jsondata = JSON.stringify(get(additionalData, "raw", []));

    const acceptEquivalentProducts = (additionalData) => {
        const acceptEquivalent = get(additionalData, "accept_equivalent", "");
        if (acceptEquivalent === true)
            return (t("accountsTable.detailsModal.true"));
        else if (acceptEquivalent === false)
            return (t("accountsTable.detailsModal.false"));

        return t("accountsTable.detailsModal.undefined");
    }

    return (
        <Modal
        title={t("accountsTable.detailsModal.title")}
        open={isModalOpen}
        footer={null} 
        maskClosable={false}
        onCancel={() => handleClose(false)}
        bodyStyle={{ overflowY: 'auto', maxHeight: 'calc(100vh - 300px)' }}
        >
            <div>
                <div>
                    <h4>{t("accountsTable.detailsModal.user")}</h4>
                    <p>{t("accountsTable.detailsModal.id")} : {get(data, "id", "")}</p>
                    <p>{t("accountsTable.detailsModal.email")} : {get(data, "email", "")}</p>
                    <p>{t("accountsTable.detailsModal.deleted")} : {get(data, "deleted", "")}</p>
                    <p>{t("accountsTable.detailsModal.firstname")} : {get(additionalData, "firstname", "")}</p>
                    <p>{t("accountsTable.detailsModal.name")} : {get(additionalData, "lastname", "")}</p>
                    <p>{t("accountsTable.detailsModal.phone")} : {get(data, "phone", "")}</p>
                </div>
                <div>
                    <h4>{t("accountsTable.detailsModal.address")}</h4>
                    <p>{t("accountsTable.detailsModal.address1")} : {get(additionalData, "address_one", "")}</p>
                    <p>{t("accountsTable.detailsModal.address2")} : {get(additionalData, "address_two", "")}</p>
                    <p>{t("accountsTable.detailsModal.city")} : {get(additionalData, "city", "")}</p>
                    <p>{t("accountsTable.detailsModal.zipCode")} : {get(additionalData, "zip_code", "")}</p>
                </div>
                <div>
                    <h4>{t("accountsTable.detailsModal.payment")}</h4>
                    <Button
                        onClick={() => validatePayment(get(additionalData, ["payment_id"], ""))}
                        disabled={get(additionalData, "mangopay_id", "")?.length === 0}
                    >
                        {t("accountsTable.detailsModal.enablePayment")}
                    </Button>
                    <p>{t("accountsTable.detailsModal.accountPaymentStatus")} : {get(data, "payment", "")}</p>
                    <p>{t("accountsTable.detailsModal.walletId")} : {get(additionalData, "wallet_id", "")}</p>
                    <p>{t("accountsTable.detailsModal.mandateId")} : {get(additionalData, "mandate_id", "")}</p>
                    <p>{t("accountsTable.detailsModal.mangopayId")} : {get(additionalData, "mangopay_id", "")}</p>
                    <p>{t("accountsTable.detailsModal.iban")} : {get(additionalData, "iban", "")}</p>
                </div>
                <div>
                    <h4>{t("accountsTable.detailsModal.description")}</h4>
                    <p>{get(additionalData, "description", t("accountsTable.detailsModal.undefined"))}</p>
                </div>
                <div>
                    <h4>{t("accountsTable.detailsModal.acceptEquivalent")}</h4>
                    <p>{acceptEquivalentProducts(additionalData)}</p>
                </div>
                <div>
                    <h4>{t("accountsTable.detailsModal.raw")}</h4>
                    <code>{jsondata}</code>
                </div>
            </div>
        </Modal>
    );
}

export {
    DetailsModal
}