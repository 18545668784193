import { createAction } from "utils/redux";

// Types
export const types = {
    GET_FACTURATION_REQUEST: "factu_GET_FACTURATION_REQUEST",
    GET_FACTURATION_SUCCESS: "factu_GET_FACTURATION_SUCCESS",
    GET_FACTURATION_FAILURE: "factu_GET_FACTURATION_FAILURE"
};

// Actions
export default {
    getFacturationRequest: (date_from, date_to) =>
        createAction(types.GET_FACTURATION_REQUEST, {
            date_from,
            date_to
        }),
    getFacturationSuccess: () =>
        createAction(types.GET_FACTURATION_SUCCESS),
    getFacturationFailure:() =>
        createAction(types.GET_FACTURATION_FAILURE),
};
