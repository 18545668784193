import { createSelector } from "reselect";
import { get } from "lodash";
const STORE_NAME = "mutualisations";

const loading = createSelector(
    (state) => get(state, [STORE_NAME, "loading"], false),
    (loading) => loading
);

const mutualisations = createSelector(
    (state) => get(state, [STORE_NAME, "mutualisations"], false),
    (mutualisations) => mutualisations
);

const suppliers = createSelector(
    (state) => get(state, [STORE_NAME, "suppliers"], false),
    (suppliers) => suppliers
);

const count = createSelector(
    (state) => get(state, [STORE_NAME, "count"], false),
    (count) => count
);

const createdStatusId = createSelector(
    (state) => get(state, [STORE_NAME, "createdStatusId"], []),
    (createdStatusId) => createdStatusId
);

const selectors = {
    mutualisations,
    loading,
    suppliers,
    count,
    createdStatusId,
};

export default selectors;
