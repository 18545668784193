import React from "react";
import store from "./store";
import history from "stores/history";
import {
  Routes,
  Route,
  Navigate,
  unstable_HistoryRouter as HistoryRouter,
} from "react-router-dom";
import { Layout } from "antd";
import { Header } from "ui/components/header";
import { Login } from "ui/screens";
import { Home } from "ui/screens/home";


export const ROUTES = {
  LOGIN: "/signin",
  HOME: "/*",
};

const LoginRoute = ({ children }) => {

  const { auth } = store.getState();

  if (auth && auth.token) {
    return <Navigate to="/" replace />;
  };

  return children;
}


const ProtectedRoute = ({ children }) => {

  const { auth } = store.getState();

  if (!auth || !auth.token) {
    return <Navigate to={ROUTES.LOGIN} replace />;
  }

  return children;
};

// Main router which handle user navigation
const Router = () => {

  return (
    <HistoryRouter history={history}>
      <Layout>
        <Header />
        <Layout>
          <Routes>
            <Route
              exact path={ROUTES.LOGIN}
              element={<LoginRoute children={<Login />} />}
            />
            <Route path={ROUTES.HOME} index element={<ProtectedRoute children={<Home />} />} />
          </Routes>
        </Layout>
      </Layout>
    </HistoryRouter>
  );
};


export default Router;

