import { Button, notification } from "antd";
import React from "react";
import { t } from "assets"

export const showSuccess = (keySuccess) => {
    notification.success({ message: t(keySuccess) });
};
export const showError = (keyError, keyDescription) => {
    notification.error({ message: t(keyError), description: t(keyDescription) });
};
export const showInfo = (keyInfo, keyDescription) => {
    const key = `open${Date.now()}`;
    const btn = (
        <Button type="primary" size="small" onClick={() => notification.close(key)}>
            Ok
        </Button>
    );
    notification.config({ duration: 0 });
    notification.info({
        message: t(keyInfo),
        description: t(keyDescription),
        btn,
        key
    });
    notification.config({ duration: 6 });
};