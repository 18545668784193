import React from "react";
import { Layout, Menu } from "antd";
import {
    UserOutlined, FolderOutlined, LogoutOutlined, ProjectOutlined
} from '@ant-design/icons';
import { t } from "assets";
import history from "stores/history";
import { useDispatch } from "react-redux";
import { AuthActions } from "stores/auth";

const AntSider = Layout.Sider;

let menuList = [
    {
        key: "main_accounts",
        title: t("sider.accounts"),
        icon: UserOutlined,
        subMenu: [
            { label: "supplier_accounts", title: t("sider.suppliers") },
            { label: "restaurant_accounts", title: t("sider.restaurants") }
        ]
    },
    {
        key: "main_products",
        title: t("sider.products"),
        icon: FolderOutlined,
        subMenu: [
            { label: "tags_mgmt", title: t("sider.tags") },
            { label: "specs_mgmt", title: t("sider.specs") },
            { label: "categories_mgmt", title: t("sider.categories") },
            { label: "mapping_mgmt", title: t("sider.mapping")},
            { label: "mutualisation_mgmt", title: t("sider.mutualisation")},
        ]
    },
    {
        key: "main_admin",
        title: t("sider.admin"),
        icon: ProjectOutlined,
        subMenu: [
            { label: "facturation_mgmt", title: t("sider.facturation") }
        ]
    },
    {
        key: "main_logout",
        title: t("sider.logout"),
        icon: LogoutOutlined,
    }
]

const menu: MenuProps['items'] = menuList.map(
    (menuList) => {
        return {
            key: menuList.key,
            icon: React.createElement(menuList.icon),
            label: menuList.title,
            children: menuList?.subMenu?.map((submenu) => {
                const subKey = submenu.label
                return {
                    key: subKey,
                    label: submenu.title,
                };
            }),
        };
    },
);

export const Sider = () => {

    const dispatch = useDispatch();

    function handleClick(e) {
        switch (e.key) {
            case 'supplier_accounts':
                history.push('/supplier_accounts')
                break;
            case 'restaurant_accounts':
                history.push('/restaurant_accounts')
                break;
            case 'tags_mgmt':
                history.push('/tags_management')
                break;
            case 'specs_mgmt':
                history.push('/specs_management')
                break;
            case 'categories_mgmt':
                history.push('/categories_management')
                break;
            case 'mapping_mgmt':
                history.push('/mapping_management')
                break;
            case 'mutualisation_mgmt':
                history.push('/mutualisation_management')
                break;
            case 'facturation_mgmt':
                history.push('/facturation_management')
                break;
            case 'main_logout':
                dispatch(AuthActions.logOut());
                break;
            default:
                history.push('/');
        };
    }

    return (
        <AntSider
            breakpoint={"lg"}
            collapsedWidth={50}
        >
            <Menu
                mode="inline"
                style={{ height: '100%', borderRight: 0 }}
                items={menu}
                onClick={handleClick}
            />
        </AntSider>
    );
}