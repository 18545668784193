import { createSelector } from "reselect";
import { get } from "lodash";
const STORE_NAME = "specs";

const specs = createSelector(
    state => get(state, [STORE_NAME, "specs"], []),
    specs => {
        return specs.map(spec => {
            const id = get(spec, ["id"], "");
            const name = get(spec, ["label"], "");
            const count = get(spec, "products_specs_aggregate.aggregate.count", 0);
            return ({
                key: id,
                name: name,
                count: count,
            });
        });
    }
);

const loading = createSelector(
    state => get(state, [STORE_NAME, "loading"], []),
    loading => {
        return loading;
    }
);

const selectors =  {
    specs,
    loading,
};

export default selectors;