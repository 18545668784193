import { takeLatest, put, call } from "redux-saga/effects";
import { TagsServices } from "services"
import { default as TagsActions, types } from "./actions";
import { showError, showSuccess } from "utils/helpers/notifications";
import { get } from "lodash";

const MUTATION_TYPES = {
    UPDATE: 'UPDATE',
    INSERT: 'INSERT',
}

//exported sagas
function* getTags() {
    yield takeLatest(types.GET_TAGS_REQUEST, getTagsSaga);
}

function* upsertTag() {
    yield takeLatest(types.UPSERT_TAG_REQUEST, upsertTagSaga);
}

function* deleteTag() {
    yield takeLatest(types.DELETE_TAG_REQUEST, deleteTagSaga);
}

//logical sagas

function* getTagsSaga() {
    const [error, response] = yield call(TagsServices.getTags);

    try {
        if (!error && response) {
            const tags = get(response, "product_tags", null);
            yield put(TagsActions.getTagsSuccess(tags));
        }
        else
            throw new Error(`Invalid response from GraphQL`)
    } catch (error) {
        console.log(error);
        yield put(TagsActions.getTagsFailure());
        showError('tagsTable.errors.fetchingData');
    }
}

function* deleteTagSaga(action) {
    try {
        const { tagId } = action;
        if (!tagId)
            throw new Error(`An id is required to delete a tag!`)

        const [error, response] = yield call(
            TagsServices.deleteTag,
            tagId
        )
        if (!response || error)
            throw new Error(`Error while deleting tag`);
        showSuccess(`tagsTable.deleteModal.success.deleteTagSaga`);
        yield put(TagsActions.deleteTagSuccess());
    } catch (error) {
        console.log(error);
        yield put(TagsActions.deleteTagFailure());
        showError(`tagsTable.deleteModal.errors.deleteTagSaga`);
    }
}


function* upsertTagSaga(action) {
    const { tagId, data } = action;
    const mutationType = tagId ? MUTATION_TYPES.UPDATE : MUTATION_TYPES.INSERT;

    try {
        const tagName = get(data, "tag-form-name", null);
        const tagColor = get(data, "tag-form-color", null);
        const tagTip = get(data, "tag-form-tooltip", null);

        if (!tagName || !tagColor) throw new Error(`Missing name or color for tag`);
        const tag = {
            label : tagName,
            color: tagColor,
            tooltip: tagTip,
        }

        let error, response = null;

        switch (mutationType) {
            case MUTATION_TYPES.UPDATE: {
                [error, response] = yield call(
                    TagsServices.updateTag,
                    tagId,
                    tag
                )
                break;
            }
            case MUTATION_TYPES.INSERT: {
                [error, response] = yield call(
                    TagsServices.insertTag,
                    tag
                )
                break;
            }
            default:
                throw new Error(`Invalid mutation type`);
        };

        if (!error && response) {
            const upsertedTagId = tagId ? tagId : 
                get(response, ["insert_product_tags_one", "id"], null);
            yield put(TagsActions.upsertTagSuccess(tag, upsertedTagId));
            showSuccess(`tagsTable.upsertModal.success.upsertTagSaga`);
            return;
        }
        throw new Error(`An error occured during ${mutationType} of tag`)
    } catch (e) {
        yield put(TagsActions.upsertTagFailure());
        showError(`tagsTable.upsertModal.errors.upsertTagSaga`);
    }
}

const sagas = [
    getTags(),
    upsertTag(),
    deleteTag(),
];

export default sagas;