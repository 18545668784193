import { Modal } from "antd";
import { MutualisationsActions } from "stores/mutualisations";
import { t } from "assets";
import { useDispatch } from "react-redux";

const DeleteModal = ({ isModalOpen, handleClose, selectedProduct }) => {
    const dispatch = useDispatch();

    return (
        <Modal
            title={t("mutualisationTable.form.deleteTitle")}
            open={isModalOpen}
            onCancel={handleClose}
            destroyOnClose={true}
            onOk={() => {
                dispatch(
                    MutualisationsActions.deleteMutualisationRequest(
                        selectedProduct.key
                    )
                );
                handleClose();
            }}
        >
            <div>
                <p>{t("mutualisationTable.form.confirmDelete")}</p>
            </div>
        </Modal>
    );
};

export { DeleteModal };
