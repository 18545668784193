import { get, set } from "lodash";
import { types } from "./actions";

const initialState = {
    smapping: [],
    suppliers: [],
    loading: false,
    count: 0,
};

function suppliersMappingReducer(state, action) {
    if (typeof state === "undefined") return initialState;

    const type = get(action, "type", "");
    switch (type) {
        case types.GET_MAPPING_SUPPLIERS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.GET_MAPPING_SUPPLIERS_SUCCESS: {
            const smapping = get(action, "smapping", {});
            const count = get(action, "count", 0);
            const products = [];
    
            smapping.forEach((item) => {
                const product = {
                    id: get(item, "id", ""),
                    supplier_name:  get(item, "supplier_information.name", ""),
                    external_ref: get(item, "external_ref", ""),
                    origin: get(item, "origin", ""),
                    sub_category: get(item, "sub_category.label", ""),
                    category: get(item, "sub_category.category.label", ""),
                };
                products.push(product);
            });

            return {
                ...state,
                smapping: products,
                loading: false,
                count: count
            };
        };
        case types.GET_MAPPING_COUNT_SUCCESS: {
            const smapping = get(action, "count", {});
            const supplierNames = [];
            const supplierNamesId = [];
    
            smapping.forEach((item) => {
                const supplier_name = get(item, "supplier_information.name", "");
                const supplier_id = get(item, "supplier_information.id", "");

                if (supplier_name && !supplierNames.includes(supplier_name)) {
                    supplierNames.push(supplier_name);
                    supplierNamesId.push({supplier_name, supplier_id})
                }
            });


            return {
                ...state,
                suppliers: supplierNamesId,
                loading: false,
            };
        }
        case types.UPSERT_SUPPLIER_MAPPING_SUCCESS: {
            const smapping = [...state.smapping];
            const idUpdatedMapping = get(action, "smappingId", null);
            const newMapping = get(action, "smapping", {});
            const indexUpdated = smapping.findIndex((sm => sm.id === idUpdatedMapping));

            if (indexUpdated !== -1) {
                set(newMapping, "id", idUpdatedMapping);
                set(smapping, [indexUpdated], newMapping);
            } else {
                set(newMapping, "id", idUpdatedMapping);
                smapping.push(newMapping);
            }
            return {
                ...state,
                loading: false,
                smapping: smapping
            };
        }
        case types.DELETE_SUPPLIER_MAPPING_SUCCESS: {
            const smapping = [...state.smapping];  
            const idRemovedMapping = get(action, "smappingId", null);
            const indexRemoved = smapping.findIndex((
                mapping =>
                    mapping.id === idRemovedMapping
                )
            );
            if (idRemovedMapping && indexRemoved !== -1)
                smapping.splice(indexRemoved, 1);
            return {
                ...state,
                loading: false,
                smapping: smapping,
            };
        }
        case types.DELETE_SPEC_FAILURE: {
            return {
                ...state,
                loading: false,
            };
        }
        case types.DELETE_SPEC_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case "":
        default:
            return state;
    }
}

export default suppliersMappingReducer;
