export const fr = {
    login: {
        title: "Veuillez vous connecter pour accéder à votre compte",
        emailLabel: "Adresse email",
        passwordLabel: "Mot de passe",
        emailPlaceholder: "Saisissez votre adresse email",
        passwordPlaceholder: "Saisissez votre mot de passe",
        connection: "Connexion",
        error: {
            email: "Merci de renseigner une adresse email valide",
            password: "Merci de renseigner votre mot de passe",
            wrongCredentials: "Email et/ou de mot passe incorrect(s) !",
            errorFieldMessage: "Le champ est requis",
            notAdmin: "Votre compte ne vous permet pas d'accéder à cette page",
            errorOversee:
                "Une erreur est survenue durant la supervision de cet utilisateur",
        },
    },
    accountsTable: {
        columns: {
            user: "Utilisateur",
            details: "Détails",
            id: "ID",
            email: "E-mail (utilisateur)",
            phone: "Téléphone",
            billing: "Compte de paiement",
            state: "Statut",
            deleted: "Supprimé",
            active: "Actif",
            oversee: "Superviser",
            export: "Exporter les produits",
            enabled: "Activé",
            disabled: "Désactivé",
        },
        detailsModal: {
            title: "Détails concernant cet utilisateur",

            id: "ID",
            user: "Utilisateur",
            email: "Email",
            deleted: "Supprimé",
            name: "Nom",
            firstname: "Prénom",
            phone: "Téléphone",
            validated: "Validé",

            city: "Ville",
            address: "Adresse",
            address1: "Ligne d'adresse 1",
            address2: "Ligne d'adresse 2",
            zipCode: "Code postal",

            payment: "Paiement",
            walletId: "walletId",
            mandateId: "mandateId",
            mangopayId: "mangopayId",
            iban: "IBAN",
            accountPaymentStatus: "Statut compte de paiement",
            enablePayment: "Activer le compte de paiement",

            description: "Description",
            undefined: "Non renseigné",
            acceptEquivalent: "Accepte des produits équivalents",
            true: "Oui",
            false: "Non",
            raw: "Informations brutes",

            monday: "Lundi",
            tuesday: "Mardi",
            wednesday: "Mercredi",
            thursday: "Jeudi",
            friday: "Vendredi",
            saturday: "Samedi",
            sunday: "Dimanche",
            siret: "SIRET",
            delivery: "Livraison",
            deliveryZones: "Zones de livraison",
            deliveryDays: "Jours de livraison",
            orderThresholds: "Seuils de livraison",
            deliveryDelay: "Délai de livraison",
            deliveryDelayThreshold: "Heure limite de commande",
            firstDeliveryDelay: "Délai première livraison",
            manualOrderConfirmation: "Confirmation de commande manuelle",
        },
        downloadModal: {
            title: "Export du catalogue produit",
            download: "Télécharger le CSV",
            missingPictures: "Indiquer les produits qui n'ont pas d'image associée",
            generateFile: "Générer le fichier",
        },
        errors: {
            fetchingData:
                "Impossible de récupérer la liste des comptes fournisseur. Veuillez réessayer.",
            validatePayment:
                "Une erreur est survenue durant la validation du compte de paiement",
            setStatus:
                "Une erreur est survenue durant la modification du statut de l'utilisateur",
            exportProducts:
                "Une erreur est survenue durant l'export des produits",
        },
        success: {
            validatePayment: "Le compte de paiement a bien été validé",
            setStatus: "Le statut de l'utilisateur a bien été modifié",
        },
        search: "Rechercher un compte",
    },
    tagsTable: {
        columns: {
            id: "ID",
            name: "Nom",
            color: "Couleur",
            tooltip: "Infobulle",
            edit: "Modifier",
            delete: "Supprimer",
        },
        upsertModal: {
            editLabel: "Modifier un statut",
            createLabel: "Créer un statut",
            overview: "Aperçu laptop / mobile",
            form: {
                nameLabel: "Nom",
                tooltipLabel: "Infobulle",
                colorLabel: "Sélectionner une couleur",
                save: "Sauvegarder",
                warningName: "Le statut doit contenir au moins 1 caractère",
                warningColor: "Veuillez choisir une couleur",
            },
            errors: {
                upsertTagSaga: "Une erreur est survenue.",
            },
            success: {
                upsertTagSaga:
                    "Les modifications ont bien été enregistrées. " +
                    "Si le nom a été modifié ou que le statut est nouveau, pensez à modifier le template Excel.",
            },
        },
        deleteModal: {
            title: "Suppression du statut",
            confirmDelete: "Confirmez-vous la suppression de ce statut ?",
            success: {
                deleteTagSaga:
                    "Le statut a bien été supprimé. " +
                    "Pensez à le retirer du template Excel.",
            },
            errors: {
                deleteTagSaga:
                    "Une erreur est survenue durant la suppression du statut",
            },
        },
        new: "Nouveau statut",
        errors: {
            fetchingData:
                "Impossible de récupérer la liste des statuts." +
                " Veuillez réessayer.",
        },
    },

    categoriesTable: {
        columns: {
            id: "ID",
            color: "Couleur",
            name: "Nom",
            order: "Ordre",
            manage: "Gérer",
        },
        manageModal: {
            title: "Gestion de",
            list: "Liste des sous-catégories",
            alertDelete:
                "Attention, seules les sous-catégories qui ne sont reliées à " +
                "aucun produit activé sont supprimables. Le cas échéant, supprimez " +
                "ou changez la sous-catégorie du/des produit(s) concerné(s) directement" +
                " depuis la page fournisseur.",
            showDeleteOptions: "Afficher les options de suppression",
            addOne: "Ajouter une sous-catégorie",
            confirm: "Ajouter",
            newCategoryName: "Nom de la nouvelle sous-catégorie",
        },
        errors: {
            fetchingData:
                "Impossible de récupérer la liste des catégories. " +
                "Veuillez réessayer.",
            deleteOne:
                "Une erreur est survenue durant la suppression de la sous-catégorie. " +
                "Vérifiez que celle-ci n'est pas associée à un/des produit(s) activé(s).",
            addOne: "Une erreur est survenue durant l'ajout de la sous-catégorie.",
        },
        success: {
            deleteOne:
                "La sous-catégorie a bien été supprimée. Pensez à la supprimer du template Excel.",
            addOne: "La sous-catégorie a bien été ajoutée. Pensez à la rajouter dans le template Excel.",
        },
    },

    specsTable: {
        columns: {
            id: "ID",
            name: "Nom",
            count: "Nombre de produits activés associés",
            edit: "Modifier",
            delete: "Supprimer",
        },
        upsertModal: {
            editSpec: "Modifier un label",
            createSpec: "Créer un label",
            form: {
                nameSpec: "Nom",
                save: "Sauvegarder",
                warningName: "Le label doit contenir au moins 1 caractère",
            },
            errors: {
                upsertSpecSaga: "Une erreur est survenue.",
            },
            success: {
                upsertSpecSaga:
                    "Les modifications ont bien été enregistrées. Pensez à modifier le template Excel.",
            },
        },
        deleteModal: {
            title: "Suppression du label",
            confirmDelete: "Confirmez-vous la suppression de ce label ?",
            success: {
                deleteSpecSaga:
                    "Le label a bien été supprimé. Pensez à modifier le template Excel.",
            },
            errors: {
                deleteSpecSaga:
                    "Une erreur est survenue durant la suppression du label. " +
                    "Vérifiez que celui-ci n'est pas associé à un/des produit(s) activé(s).",
            },
            alertDelete: "",
        },
        new: "Nouveau label",
        errors: {
            fetchingData:
                "Impossible de récupérer la liste des labels. Veuillez réessayer.",
        },
        search: "Rechercher un label",
    },

    mappingSuppliersTable: {
        columns: {
            IdSupplier: "ID Fournisseur",
            supplierName: "Nom fournisseur",
            editMapping: "Modifier les correspondances du fournisseur",
            productRef: "Référence produit",
            category: "Catégorie",
            subCategory: "Sous-catégorie",
            origin: "Origine",
            editProductMapping: "Modifier",
            deleteProductMapping: "Supprimer",
        },
        search: "Rechercher une référence, une sous-catégorie ou une origine",
        add: "Ajouter une correspondance produit",
        form: {
            editTitle: "Produit à modifier",
            deleteTitle: "Suppression d'une correspondance",
            confirmDelete:
                "Confirmez-vous la suppression de cette correspondance ?",
            newTitle: "Nouveau produit",
            category: "Catégorie",
            categoryPlaceholder: "Sélectionner une catégorie",
            subCategory: "Sous-catégorie",
            subCategoryPlaceholder: "Sélectionner une sous-catégorie",
            origin: "Origine (optionnel)",
            originPlaceholder: "Entrez une origine si vous la connaissez",
            ref: "Référence produit",
            refPlaceholder: "Entrez la référence produit",
            save: "Sauvegarder",

            errors: {
                upsertMappingSupplierSaga:
                    "Une erreur est survenue durant la modification des correspondances produit.",
                deleteMappingSupplierSaga:
                    "Une erreur est survenue durant la suppression de la correspondance produit.",
                mandatoryCategory: "Veuillez sélectionner une catégorie",
                mandatorySubCategory:
                    "Veuillez sélectionner une sous-catégorie",
                mandatoryRef: "Veuillez entrer une référence produit",
                getMappingSupplierSaga:
                    "Une erreur est survenue durant la récupération des correspondances produit.",
            },
            success: {
                upsertMappingSupplierSaga:
                    "La correspondance produit a bien été pris en compte.",
                deleteMappingSupplierSaga:
                    "La correspondance produit a bien été supprimé.",
            },
        },
    },
    mutualisationTable: {
        columns: {
            id: "ID",
            endMutualisation: "Date de fin de mutualisation",
            deliveryDelay: "Date de livraison",
            address: "Adresse de livraison",
            threshold: "Seuil",
            supplierName: "Fournisseur",
            dispatchFees: "Frais de port",
            status: "Statut",
            delete: "Supprimer",
        },
        form: {
            title: "Nouvelle mutualisation",
            supplierName: "Fournisseur",
            supplierNamePlaceholder: "Sélectionner un fournisseur",
            endMutualisation: "Date de fin de mutualisation",
            endMutualisationPlaceholder:
                "Sélectionner une date de fin de mutualisation",
            deliveryDelay: "Délai de livraison",
            deliveryDelayPlaceholder: "Sélectionner un délai de livraison",
            threshold: "Seuil",
            thresholdPlaceholder: "Entrez un seuil",
            dispatchFees: "Frais de port",
            dispatchFeesPlaceholder: "Entrez des frais de port",
            address: "Adresse de livraison",
            addressPlaceholder: "Entrez une adresse de livraison",
            address2: "Complément d'adresse",
            address2Placeholder: "Complément d'adresse (FACULTATIF)",
            cityPlaceholder: "Entrez une ville",
            status: "Statut",
            statusPlaceholder: "Sélectionner un statut",
            deleteTitle: "Suppression d'une mutualisation",
            confirmDelete:
                "Confirmez-vous la suppression de cette mutualisation ?",
            save: "Sauvegarder",
            errors: {
                mandatorySupplier: "Veuillez sélectionner un fournisseur",
                mandatoryEndMutualisation: "Veuillez sélectionner une date",
                mandatoryDeliveryDelay: "Veuillez sélectionner un délai",
                mandatoryThreshold: "Veuillez entrer un seuil",
                mandatoryDispatchFees: "Veuillez entrer des frais de port",
                mandatoryAddress: "Veuillez entrer une adresse de livraison",
                mandatoryStatus: "Veuillez sélectionner un statut",
                insertMutualisationsSaga:
                    "Une erreur est survenue durant la création de la mutualisation.",
                fetchingData:
                    "Impossible de récupérer la liste des mutualisations. Veuillez réessayer.",
            },
            success: {
                insertMutualisationsSaga: "La mutualisation a bien été créée.",
            },
        },
        deleteModal: {
            title: "Suppression de la mutualisation",
            confirmDelete:
                "Confirmez-vous la suppression de cette mutualisation ?",
            success: {
                deleteMutualisationSaga:
                    "La mutualisation a bien été supprimée.",
            },
            errors: {
                deleteMutualisationSaga:
                    "Une erreur est survenue durant la suppression de la mutualisation.",
            },
        },
        new: "Nouvelle mutualisation",
        search: "Rechercher une mutualisation par fournisseur ou par statut",
        errors: {
            fetchingData:
                "Impossible de récupérer la liste des mutualisations. Veuillez réessayer.",
        },
    },

    sider: {
        accounts: "Comptes",
        suppliers: "Fournisseurs",
        restaurants: "Restaurateurs",
        products: "Produits",
        logout: "Déconnexion",
        tags: "Statuts",
        specs: "Labels",
        categories: "Catégories",
        mapping: "Ref. fournisseurs",
        mutualisation: "Mutualisations",
        admin: "Administration",
        facturation: "Facturation",
    },
    facturation: {
        date_from: "Date de début",
        date_to: "Date de fin",
        download: "Télécharger les commmandes",
        error: "Erreur lors du téléchargement du fichier"
    },
};
