import { applyMiddleware, compose, createStore } from "redux";
import createSagaMiddleware from "redux-saga";
import { loadState, saveState } from "utils/storage";
import { history } from "core/history";
import { rootSagas, rootReducers } from "stores";

const sagaMiddleware = createSagaMiddleware();

// Retrieves state from local storage
const persistedState = loadState();

// Configure store middleware
const middleware = [
  // Info: Apply sagaMiddleware in first !
  /*applyMiddleware(logger),*/
  applyMiddleware(sagaMiddleware),
].concat(
  process.env.NODE_ENV === "development"
    ? [
      window.__REDUX_DEVTOOLS_EXTENSION__ &&
        window.__REDUX_DEVTOOLS_EXTENSION__()
        ? window.__REDUX_DEVTOOLS_EXTENSION__()
        : compose,
    ]
    : []
);

// TODO: Handle deprecated
// Create store
const store = createStore(rootReducers, persistedState, compose(...middleware));

// Each sagas are isolated
// This ensure that the sagas will not be canceled if one failed
// (Be aware that should be a source of performance leak)
rootSagas.forEach((saga) =>
  sagaMiddleware.run(function* () {
    yield saga;
  }, history)
);

// Save state when changes occurred
store.subscribe(() => {
  const { auth, restaurants, suppliers, users, beneficiaries, events, orientations, tags } =
    store.getState();
  saveState({ auth, restaurants, suppliers, users, beneficiaries, events, orientations, tags });
});

export default store;
