import { createSelector } from "reselect";
import { get } from "lodash";
const STORE_NAME = "facturation";

const loading = createSelector(
    (state) => get(state, [STORE_NAME, "loading"], []),
    (loading) => {
        return loading;
    }
);

const facturationSelector = {
    loading,
};

export default facturationSelector;
