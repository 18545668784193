import { takeLatest, put, call } from "redux-saga/effects";
import { SpecsServices } from "services"
import { default as SpecsActions, types } from "./actions";
import { showError, showSuccess } from "utils/helpers/notifications";
import { get } from "lodash";

const MUTATION_TYPES = {
    UPDATE: 'UPDATE',
    INSERT: 'INSERT',
}

//exported sagas
function* getSpecs() {
    yield takeLatest(types.GET_SPECS_REQUEST, getSpecsSaga);
}

function* upsertSpec() {
    yield takeLatest(types.UPSERT_SPEC_REQUEST, upsertSpecSaga);
}

function* deleteSpec() {
    yield takeLatest(types.DELETE_SPEC_REQUEST, deleteSpecSaga);
}

//logical sagas

function* getSpecsSaga(action) {
    let filter = {};
    if (action?.searchValue?.length > 0) {
        filter = {
            label: {_ilike: `%${action.searchValue}%`}
        };
    }
    const [error, response] = yield call(SpecsServices.getSpecs, filter);

    try {
        if (!error && response) {
            const specs = get(response, "specs", null);
            yield put(SpecsActions.getSpecsSuccess(specs));
        }
        else
            throw new Error(`Invalid response from GraphQL`)
    } catch (error) {
        console.log(error);
        yield put(SpecsActions.getSpecsFailure());
        showError('specsTable.errors.fetchingData');
    }
}

function* deleteSpecSaga(action) {
    try {
        const { specId } = action;
        if (!specId)
            throw new Error(`An id is required to delete a spec!`)

        let [error, response] = yield call(
            SpecsServices.deleteAffectedProducts,
            specId
        )
        if (!response || error)
        throw new Error(`Error while deleting products_specs affected to this spec!`);

        [error, response] = yield call(
            SpecsServices.deleteSpec,
            specId
        )
        if (!response || error)
            throw new Error(`Error while deleting spec`);
        showSuccess(`specsTable.deleteModal.success.deleteSpecSaga`);
        yield put(SpecsActions.deleteSpecSuccess(specId));
    } catch (error) {
        console.log(error);
        yield put(SpecsActions.deleteSpecFailure());
        showError('specsTable.deleteModal.errors.deleteSpecSaga');
    }
}


function* upsertSpecSaga(action) {
    const { specId, data } = action;
    const mutationType = specId ? MUTATION_TYPES.UPDATE : MUTATION_TYPES.INSERT;

    try {
        const specName = get(data, "spec-form-name", null);

        if (!specName) throw new Error(`Missing name for spec`);
        const spec = {
            label : specName
        }
        let error, response = null;

        switch (mutationType) {
            case MUTATION_TYPES.UPDATE: {
                [error, response] = yield call(
                    SpecsServices.updateSpec,
                    specId,
                    spec
                )
                break;
            }
            case MUTATION_TYPES.INSERT: {
                [error, response] = yield call(
                    SpecsServices.insertSpec,
                    spec
                )
                break;
            }
            default:
                throw new Error(`Invalid mutation type`);
        };

        if (!error && response) {
            const upsertedSpecId = specId ? specId : 
                get(response, ["insert_specs_one", "id"], null);
            yield put(SpecsActions.upsertSpecSuccess(spec, upsertedSpecId));
            showSuccess(`specsTable.upsertModal.success.upsertSpecSaga`);
            return;
        }
        throw new Error(`An error occured during ${mutationType} of spec`)
    } catch (e) {
        yield put(SpecsActions.upsertSpecFailure());
        showError(`specsTable.upsertModal.errors.upsertSpecSaga`);
    }
}

const sagas = [
    getSpecs(),
    upsertSpec(),
    deleteSpec(),
];

export default sagas;