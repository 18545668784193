import { createAction } from "utils/redux";

// Types
export const types = {
    GET_SUPPLIERS_REQUEST: "suppliers_GET_SUPPLIERS_REQUEST",
    GET_SUPPLIERS_NAMES_REQUEST: "suppliers_GET_SUPPLIERS_NAMES_REQUEST",
    GET_SUPPLIERS_SUCCESS: "suppliers_GET_SUPPLIERS_SUCCESS",
    GET_SUPPLIERS_FAILURE: "suppliers_GET_SUPPLIERS_FAILURE",

    VALIDATE_PAYMENT_REQUEST: "suppliers_VALIDATE_PAYMENT_REQUEST",
    VALIDATE_PAYMENT_SUCCESS: "suppliers_VALIDATE_PAYMENT_SUCCESS",
    VALIDATE_PAYMENT_FAILURE: "suppliers_VALIDATE_PAYMENT_FAILURE",

    SET_STATUS_REQUEST: "suppliers_SET_STATUS_REQUEST",
    SET_STATUS_SUCCESS: "suppliers_SET_STATUS_SUCCESS",
    SET_STATUS_FAILURE: "suppliers_SET_STATUS_FAILURE",

    EXPORT_PRODUCTS_REQUEST: "suppliers_EXPORT_PRODUCTS_REQUEST",
    EXPORT_PRODUCTS_SUCCESS: "suppliers_EXPORT_PRODUCTS_SUCCESS",
    EXPORT_PRODUCTS_CLEAR: "suppliers_EXPORT_PRODUCTS_CLEAR",
};

// Actions
export default {
    getSuppliersRequest: (offset, limit, searchValue) =>
        createAction(types.GET_SUPPLIERS_REQUEST, {
            offset,
            limit,
            searchValue,
        }),
    getSuppliersNamesRequest: () =>
        createAction(types.GET_SUPPLIERS_NAMES_REQUEST, {}),
    getSuppliersSuccess: (suppliers, count) =>
        createAction(types.GET_SUPPLIERS_SUCCESS, { suppliers, count }),
    getSuppliersFailure: () => createAction(types.GET_SUPPLIERS_FAILURE),

    validatePaymentRequest: (id, key) =>
        createAction(types.VALIDATE_PAYMENT_REQUEST, { id, key }),
    validatePaymentSuccess: (key) =>
        createAction(types.VALIDATE_PAYMENT_SUCCESS, { key }),
    validatePaymentFailure: () => createAction(types.VALIDATE_PAYMENT_FAILURE),

    setStatusRequest: (userId, key, status) =>
        createAction(types.SET_STATUS_REQUEST, { userId, key, status }),
    setStatusSuccess: (key, status) =>
        createAction(types.SET_STATUS_SUCCESS, { key, status }),
    setStatusFailure: (key, status) =>
        createAction(types.SET_STATUS_FAILURE, { key, status }),

    exportProductsRequest: (supplierId) =>
        createAction(types.EXPORT_PRODUCTS_REQUEST, { supplierId }),
    exportProductsSuccess: (products) => createAction(types.EXPORT_PRODUCTS_SUCCESS, { products }),
    exportProductsClear: () => createAction(types.EXPORT_PRODUCTS_CLEAR),
};
