import { authenticatedQuery } from "./middlewares";
import { mutualisationQueries } from "./graphQLRequests";
import { mutualisationMutations } from "./graphQLRequests";

const mapping = {
    getMutualisationCount: () =>
        authenticatedQuery(mutualisationQueries.getMappingCount, {}, {}),
    getMutualisations: (filter, offset, limit, order) =>
        authenticatedQuery(
            mutualisationQueries.getMutualisations,
            {},
            { filter, offset, limit, order }
        ),
    getAddress: (filter) =>
        authenticatedQuery(mutualisationQueries.getAddress, {}, { filter }),
    insertMutualisations: (data) =>
        authenticatedQuery(
            mutualisationMutations.insertMutualisations,
            {},
            { data }
        ),
    insertAddress: (data) =>
        authenticatedQuery(mutualisationMutations.insertAddress, {}, { data }),
    deleteMutalisation: (id) =>
        authenticatedQuery(
            mutualisationMutations.deleteMutualisation,
            {},
            { id }
        ),
};

export default mapping;
