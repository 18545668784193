import { Table } from "antd";

export const DataTable = props => {

    const {
        columns,
        dataSource,
        pagination,
        handlePageChange,
        loading,
        onChange
    } = props;

    return (
        <Table
            columns={columns}
            dataSource={dataSource}
            scroll={{ x: "max-content" }}
            pagination={pagination}
            onChange={handlePageChange}
            loading={loading}
            onChange={onChange}
        />
    );
}
