import React from "react";
import { Navigate } from "react-router-dom";
import { Layout } from "antd";
import { Suppliers } from "../accounts/supplier_accounts";
import { Restaurants } from "../accounts/restaurants_accounts";
import { Tags } from "../tags";
import { Specs } from "../specs";
import { Categories } from "../categories";
import { Mapping } from "../mapping";
import { Mutualisation } from "../mutualisation";
import { Sider } from "ui/components/sider";

import {
    Routes,
    Route
} from "react-router-dom";
import {Facturation} from "../../facturation";
const { Content } = Layout;

export const ROUTES = {
    HOME: "/",
    SUPPLIER_ACCOUNTS: "/supplier_accounts",
    RESTAURANT_ACCOUNTS: "/restaurant_accounts",
    TAGS: "/tags_management",
    SPECS: "/specs_management",
    CATEGORIES: "/categories_management",
    MAPPING: "/mapping_management",
    MUTUALISATION: "/mutualisation_management",
    FACTURATION: "/facturation_management",
};

export const Home = () => {

    return (
        <React.Fragment>
            <Sider />
            <Content>
                <Routes>
                    <Route exact path={ROUTES.HOME} element={<Navigate to="/supplier_accounts" />} />
                    <Route path={ROUTES.SUPPLIER_ACCOUNTS} element={<Suppliers />} />
                    <Route path={ROUTES.RESTAURANT_ACCOUNTS} element={<Restaurants />} />
                    <Route path={ROUTES.TAGS} element={<Tags />} />
                    <Route path={ROUTES.SPECS} element={<Specs />} />
                    <Route path={ROUTES.CATEGORIES} element={<Categories />} />
                    <Route path={ROUTES.MAPPING} element={<Mapping />} />
                    <Route path={ROUTES.MUTUALISATION} element={<Mutualisation />} />
                    <Route path={ROUTES.FACTURATION} element={<Facturation />} />
                </Routes>
            </Content>
        </React.Fragment>
    );
}