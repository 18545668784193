import { takeLatest, put, call } from "redux-saga/effects";
import { CategoriesServices } from "services";
import { default as CategoriesActions, types } from "./actions";
import { showError, showSuccess } from "utils/helpers/notifications";
import { get } from "lodash";

//exported sagas
function* getCategories() {
    yield takeLatest(types.GET_CATEGORIES_REQUEST, getCategoriesSaga);
}

function* addSubCategory() {
    yield takeLatest(types.ADD_SUB_CATEGORY_REQUEST, addSubCategorySaga);
}

function* deleteSubCategory() {
    yield takeLatest(types.DELETE_SUB_CATEGORY_REQUEST, deleteSubCategorySaga);
}

//logical sagas

function* getCategoriesSaga(action) {
    const [error, response] = yield call(CategoriesServices.getCategories);
    try {
        if (!error && response) {
            const categories = get(response, "categories", null);
            yield put(CategoriesActions.getCategoriesSuccess(categories));
        } else throw new Error(`Invalid response from GraphQL`);
    } catch (error) {
        yield put(CategoriesActions.getCategoriesFailure());
        showError("mutualisationTable.errors.fetchingData");
    }
}

function* deleteSubCategorySaga(action) {
    const { idToDelete, idParent } = action;

    try {
        if (!idToDelete || !idParent)
            throw new Error(
                `Missing id of sub category or parent category to delete!`
            );

        // before deleting subcategory, we need to delete
        // all products_subcategories linked to this subcategory
        let [error, response] = yield call(
            CategoriesServices.deleteAffectedProducts,
            idToDelete
        );
        if (!response || error)
            throw new Error(
                `Error while deleting products_subcategories` +
                    `affected to this subcategory!`
            );

        // if no error, then we can delete the subcategory
        [error, response] = yield call(
            CategoriesServices.deleteSubCategory,
            idToDelete
        );
        if (!response || error)
            throw new Error(`Error while deleting subcategory!`);

        showSuccess(`categoriesTable.success.deleteOne`);
        yield put(
            CategoriesActions.deleteSubCategorySuccess(idToDelete, idParent)
        );
    } catch (error) {
        yield put(CategoriesActions.deleteSubCategoryFailure());
        showError(`categoriesTable.errors.deleteOne`);
    }
}

function* addSubCategorySaga(action) {
    const { subCategory } = action;

    try {
        if (!subCategory?.id_category || !subCategory?.label)
            throw new Error(
                `Missing id of parent category or new subcategory!`
            );

        const [error, response] = yield call(
            CategoriesServices.insertSubCategory,
            subCategory
        );
        if (!response || error)
            throw new Error(`Error while adding subcategory`);
        const idInserted = get(response, "insert_sub_categories_one.id", -1);
        showSuccess(`categoriesTable.success.addOne`);
        yield put(
            CategoriesActions.addSubCategorySuccess(idInserted, subCategory)
        );
    } catch (e) {
        yield put(CategoriesActions.addSubCategoryFailure());
        showError(`categoriesTable.errors.addOne`);
    }
}

const sagas = [getCategories(), addSubCategory(), deleteSubCategory()];

export default sagas;
