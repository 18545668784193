import { authenticatedQuery } from './middlewares';
import { specQueries } from './graphQLRequests';
import { specMutations } from './graphQLRequests';

const specs = {
    getSpecs: (filter) => authenticatedQuery(specQueries.getSpecs, {}, {filter}),
    insertSpec: (data) => authenticatedQuery(specMutations.insertSpec, {}, {data}),
    updateSpec: (id, data) => authenticatedQuery(specMutations.updateSpec, {}, {id, data}),

    deleteAffectedProducts: (id) => authenticatedQuery(specMutations.deleteAffectedProducts, {}, {id}),
    deleteSpec: (id) => authenticatedQuery(specMutations.deleteSpec, {}, {id}),
}

export default specs;