import { useEffect, useState } from "react";
import { MappingSuppliersActions, MappingSuppliersSelectors } from "stores/supplier_mapping";
import { CategoriesActions, CategoriesSelectors } from "stores/categories";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "antd";
import { DataTable } from "ui/components/table";
import { Loader } from "ui/components/loader";
import { t } from "assets";
import { SearchBar } from "components/SearchBar";
import "./style.scss";
import { CloseOutlined } from '@ant-design/icons';
import { ManageModal } from "./ManageModal";
import { DeleteModal } from "./DeleteModal";

const MODAL_ACTIONS = {
    MANAGE: "manage",
    DELETE: "delete",
};

export const Mapping = () => {
    const [supplier, setSupplier] = useState([]);
    const [searchValue, onSearchChange] = useState("");
    const [isManageModal, setManageModal] = useState(false);
    const [isDeleteModal, setDeleteModal] = useState(false);
    const [addProduct, setAddProduct] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);

    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(10);

    const setSupplierAndReset = (supplier) => {
        setSupplier(supplier);
        setOffset(0);
        setLimit(10);
        onSearchChange("");
    }

    const supplierColumns = [
        {
            title: t("mappingSuppliersTable.columnsIdSupplier"),
            dataIndex: "supplier_id",
        },
        {
            title: t("mappingSuppliersTable.columns.supplierName"),
            dataIndex: "supplier_name",
        },
        {
            title: t("mappingSuppliersTable.columns.editMapping"),
            render: (_, value) => (
                <Button
                    onClick={() => setSupplierAndReset(value)}
                >
                    {t("mappingSuppliersTable.columns.editMapping")}
                </Button>
            )
        }
    ];


    const productsColumns = [
        {
            title: t("mappingSuppliersTable.columns.supplierName"),
            dataIndex: "supplier_name",
        },
        {
            title: t("mappingSuppliersTable.columns.productRef"),
            dataIndex: "external_ref",
        },
        {
            title: t("mappingSuppliersTable.columns.category"),
            dataIndex: "category",
        },
        {
            title: t("mappingSuppliersTable.columns.subCategory"),
            dataIndex: "sub_category",
        },
        {
            title: t("mappingSuppliersTable.columns.origin"),
            dataIndex: "origin",
        },
        {
            title: t("mappingSuppliersTable.columns.editProductMapping"),
            render: (_, value) => (
                <Button
                    onClick={() => selectProduct(value, MODAL_ACTIONS.MANAGE)}
                >
                    {t("mappingSuppliersTable.columns.editProductMapping")}
                </Button>
            )
        },
        {
            title: t("mappingSuppliersTable.columns.deleteProductMapping"),
            render: (_, value) => (
                <Button
                    onClick={() => selectProduct(value, MODAL_ACTIONS.DELETE)}
                >
                    {t("mappingSuppliersTable.columns.deleteProductMapping")}
                </Button>
            )
        }
    ];

    const dispatch = useDispatch();

    const supp = useSelector(MappingSuppliersSelectors.suppliers);
    const prods = useSelector(MappingSuppliersSelectors.smapping);
    const count = useSelector(MappingSuppliersSelectors.count);
    const loading = useSelector(MappingSuppliersSelectors.loading);
    const categories = useSelector(CategoriesSelectors.categories);

    const selectProduct = (product, type) => {
        setSelectedProduct(product);
        
        if (type === MODAL_ACTIONS.MANAGE) {
            setAddProduct(false);
            setManageModal(true);
        }
        if (type === MODAL_ACTIONS.DELETE) {
            setDeleteModal(true);
        }
    }

    useEffect(() => {
        if (supplier?.supplier_id)
            dispatch(MappingSuppliersActions.getMappingSuppliersRequest(offset, limit, searchValue, supplier));
        else
            dispatch(MappingSuppliersActions.getMappingCountRequest());

    }, [offset, limit, searchValue, supplier, dispatch]);

    useEffect(() => {
        dispatch(CategoriesActions.getCategoriesRequest());
    }, [dispatch]);

    useEffect(() => {
        setOffset(0);
        setLimit(10);
    }, [searchValue]);

    const addProductModal = () => {
        setAddProduct(true);
        setManageModal(true);
    }

    const loader = {
        spinning: loading,
        indicator: <Loader />,
    }

    
    return (
        <div>
            <div className="mapping_header">
                {supplier?.supplier_id > 0 && (
                    <SearchBar onChange={onSearchChange} placeholder={t("mappingSuppliersTable.search")} />
                )}
                {supplier?.supplier_id > 0 && <div className="selected_supplier">
                    <p>Fournisseur sélectionné&nbsp;:&nbsp;</p>
                    <Button
                        type="primary"
                        icon={<CloseOutlined onClick={() => setSupplierAndReset([])}/>}
                    >
                        {supplier?.supplier_name}
                    </Button>
                    <Button onClick={() => addProductModal()}>
                        {t("mappingSuppliersTable.add")}
                    </Button>
                </div>}
            </div>

            {supplier?.supplier_id && (
                <DataTable 
                    columns={productsColumns}
                    dataSource={(prods)}
                    loading={loader}
                    pagination={{
                        pageSizeOptions: ["5", "10", "25", "100"],
                        showSizeChanger: true,
                        total: count,
                        onChange: (page, pageSize) => {
                            setOffset((page - 1) * pageSize);
                            setLimit(pageSize);
                        }
                    }}
                />
            )}
            {!supplier?.supplier_id && (
                <DataTable 
                    columns={supplierColumns}
                    dataSource={supp}
                    loading={loader}
                />
            )}
            <ManageModal
                selectedProduct={selectedProduct}
                supplierId={supplier?.supplier_id}
                categories={categories}
                addProduct={addProduct}
                isModalOpen={isManageModal}
                handleClose={() => setManageModal(false)}
            />
            <DeleteModal 
                selectedProduct={selectedProduct}
                isModalOpen={isDeleteModal}
                handleClose={() => setDeleteModal(false)}
            />
        </div>
    );
}