import { useEffect, useState } from "react";
import { CategoriesActions, CategoriesSelectors } from "stores/categories";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "antd";
import { DataTable } from "ui/components/table";
import { Loader } from "ui/components/loader";
import { t } from "assets";
import { ManageModal } from "./ManageModal";

export const Categories = () => {

    const columns = [
        {
            title: t("categoriesTable.columns.id"),
            dataIndex: "key",
        },
        {
            title: t("categoriesTable.columns.order"),
            dataIndex: "order",
        },
        {
            title: t("categoriesTable.columns.name"),
            dataIndex: "name",
        },
        {
            title: t("categoriesTable.columns.manage"),
            render: (_, value) => (
                <Button onClick={() => manageCategory(value?.key)}>
                    {t("categoriesTable.columns.manage")}
                </Button>
            )
        },
    ];

    const dispatch = useDispatch();
    const categories = useSelector(CategoriesSelectors.categories);
    const loading = useSelector(CategoriesSelectors.loading);
    const [isManageModalOpen, setDisplayModal] = useState(false);
    const [idCategoryToManage, setIdCategory] = useState(-1);

    useEffect(() => {
        dispatch(CategoriesActions.getCategoriesRequest());
    }, [dispatch]);

    const loader = {
        spinning: loading,
        indicator: <Loader />,
    }

    const manageCategory = (id) => {
        setIdCategory(id);
        setDisplayModal(true);
    };

    const handleClose = () => {
        setDisplayModal(false);
    };

    const addSubCategory = (newSubCategoryName) => {
        const subCategory = {
            label: newSubCategoryName,
            id_category: idCategoryToManage,
        }

        dispatch(CategoriesActions.addSubCategoryRequest(subCategory));
    };

    const deleteSubCategory = (id) => {
        dispatch(CategoriesActions.deleteSubCategoryRequest(id, idCategoryToManage));
    };

    return (
        <div>
            <DataTable columns={columns} dataSource={categories} loading={loader} />
            <ManageModal
                isModalOpen={isManageModalOpen}
                handleClose={handleClose}
                categories={categories}
                idCategoryToManage={idCategoryToManage}
                addSubCategory={addSubCategory}
                deleteSubCategory={deleteSubCategory}
                loading={loading}
            />
        </div>
    );
}