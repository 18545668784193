const queries =  {
  getMappingSuppliers: `
    query getMappingSuppliers($filter: mapping_catalog_bool_exp, $offset: Int, $limit: Int) {
      mapping_catalog(offset : $offset, limit: $limit, where: $filter) {
        id,
        supplier_information {
          id,
          name
        },
        sub_category {
          label,
          category {
            label
          }
        },
        external_ref,
        origin
      },
      mapping_catalog_aggregate(where: $filter) {
        aggregate {
          count
        }
      }
    },
  `,

  getMappingCount : `
  query getMappingSuppliers {
    mapping_catalog(distinct_on: id_supplier) {
      supplier_information {
        id,
        name
      }
    }
  },
    `,
}

export default queries;