import { useState, useImperativeHandle, forwardRef } from "react";
import { SpecsActions } from "stores/specs";
import { useDispatch } from "react-redux";
import { Modal } from "antd";
import { t } from "assets";
import { Input, Form, Button } from 'antd';
import { get } from "lodash";
import "./style.scss";

const MODAL_PROPERTIES = {
    EDIT: 'edit_spec',
    EDIT_TITLE:  t("specsTable.upsertModal.editSpec"),

    NEW: 'new_spec',
    NEW_TITLE:  t("specsTable.upsertModal.createSpec"),

    DEFAULT_LABEL_NAME: 'label',
};

const UpsertModalComponent = ({ isModalOpen, setDisplayModal }, ref) => {
    const [modalTitle, setModalTitle] = useState("");

    const [specId, setSpecId] = useState(null);
    const [newSpecName, setSpecName] = useState("");

    const dispatch = useDispatch();

    useImperativeHandle(ref, () => ({
        showModal(type, value)  {
            if (type === MODAL_PROPERTIES.EDIT) {
                setModalTitle(MODAL_PROPERTIES.EDIT_TITLE);
                setSpecId(get(value, "key", ""));
                setSpecName(get(value, "name", ""));
            } else {
                setSpecId(null);
                setModalTitle(MODAL_PROPERTIES.NEW_TITLE)
                setSpecName(MODAL_PROPERTIES.DEFAULT_LABEL_NAME)
            }
            setDisplayModal(true);
        }
    }));

    const onFormSubmit = (data: any) => {
        dispatch(SpecsActions.upsertSpecRequest(specId, data));
        setDisplayModal(false);
    };

    const handleClose = () => {
        setDisplayModal(false);
    };

    return (
        <Modal
            title={modalTitle}
            open={isModalOpen}
            footer={null} 
            maskClosable={false}
            onCancel={handleClose}
            destroyOnClose
        >
            <Form
                name="spec-form"
                layout="vertical"
                className="spec-form"
                onFinish={onFormSubmit}
                initialValues={{
                    "spec-form-name": newSpecName
                }}
                preserve={false}
            >
                <Form.Item
                    name="spec-form-name"
                    label={t("specsTable.upsertModal.form.nameLabel")}
                    rules={[
                        {
                            async validator(_, value) {
                                if (!value || value.length === 0) {
                                    return Promise.reject(new Error(t("specsTable.upsertModal.form.warningName")));
                                }
                                return Promise.resolve();
                            }, required: true
                        },
                    ]}
                >
                    <Input onChange={(e) => setSpecName(e.target.value)}/>
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        {t("specsTable.upsertModal.form.save")}
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};

const UpsertModal = forwardRef(UpsertModalComponent);

export  {
    UpsertModal,
    MODAL_PROPERTIES
}
