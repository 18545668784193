import { Input } from "antd";
import "./style.scss";

const Search = Input.Search;

export const SearchBar = ({onChange, placeholder = ""}) => {
  return (
      <div className="search">
        <Search
          placeholder={placeholder}
          onChange={e => onChange(e.target.value)}
          enterButton />
      </div>
  );
}