
import { methods } from "utils/services";
import { service } from "./middlewares";

const ROUTES = {
    getFactu: process.env.REACT_APP_BASE_API_V2_URL + '/admin/month-orders'
}
const getFacturation = (token, date_from, date_to) => service(methods.GET, ROUTES.getFactu, {},
        {
            from: date_from,
            to: date_to,
            format: 'file'
        },
        {
            'Authorization': `Bearer ${token}`,
        }
    );

export default {
    getFacturation
}