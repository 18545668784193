import { createAction } from "utils/redux";

// Types
export const types = {
    GET_MUTUALISATIONS_REQUEST: "mutualisations_GET_MUTUALISATIONS_REQUEST",
    GET_MUTUALISATIONS_COUNT_REQUEST:
        "mutualisations_GET_MUTUALISATIONS_COUNT_REQUEST",
    GET_MUTUALISATIONS_COUNT_SUCCESS:
        "mutualisations_GET_MUTUALISATIONS_COUNT_SUCCESS",
    GET_MUTUALISATIONS_SUCCESS: "mutualisations_GET_MUTUALISATIONS_SUCCESS",

    INSERT_MUTUALISATION_REQUEST: "mutualisations_INSERT_MUTUALISATION_REQUEST",
    INSERT_MUTUALISATION_SUCCESS: "mutualisations_INSERT_MUTUALISATION_SUCCESS",
    INSERT_MUTUALISATION_FAILURE: "mutualisations_INSERT_MUTUALISATION_FAILURE",

    DELETE_MUTUALISATIONS_REQUEST:
        "mutualisations_DELETE_MUTUALISATIONS_REQUEST",
    DELETE_MUTUALISATION_SUCCESS: "mutualisations_DELETE_MUTUALISATION_SUCCESS",
};

// Actions
const getMutualisationsRequest = (offset, limit, searchValue, sort) =>
    createAction(types.GET_MUTUALISATIONS_REQUEST, {
        offset,
        limit,
        searchValue,
        sort
    });
const getMutualisationsSuccess = (mutualisations, count, createdStatusId) =>
    createAction(types.GET_MUTUALISATIONS_SUCCESS, {
        mutualisations,
        count,
        createdStatusId,
    });

const getMutualisationCountRequest = () =>
    createAction(types.GET_MUTUALISATIONS_COUNT_REQUEST);
const getMutualisationCountSuccess = (count) =>
    createAction(types.GET_MUTUALISATIONS_COUNT_SUCCESS, { count });

const insertMutualisationRequest = (data) =>
    createAction(types.INSERT_MUTUALISATION_REQUEST, { data });
const insertMutualisationSuccess = (mutualisations, data, insertedIds) =>
    createAction(types.INSERT_MUTUALISATION_SUCCESS, {
        mutualisations,
        data,
        insertedIds,
    });

const deleteMutualisationRequest = (mutualisationId) =>
    createAction(types.DELETE_MUTUALISATIONS_REQUEST, { mutualisationId });
const deleteMutualisationSuccess = (mutualisationId) =>
    createAction(types.DELETE_MUTUALISATION_SUCCESS, { mutualisationId });

const actions = {
    getMutualisationsRequest,
    getMutualisationsSuccess,

    getMutualisationCountRequest,
    getMutualisationCountSuccess,

    insertMutualisationRequest,
    insertMutualisationSuccess,

    deleteMutualisationRequest,
    deleteMutualisationSuccess,
};

export default actions;
