import { t } from "assets";
import { createSelector } from "reselect";
import { get } from "lodash";
const STORE_NAME = "suppliers";

const suppliers = createSelector(
    (state) => get(state, [STORE_NAME, "suppliers"], []),
    (suppliers) => {
        if (!suppliers || suppliers.constructor !== Array) return [];

        return suppliers.map((supplier) => {
            const user = get(supplier, ["users", 0], "");
            const name = get(supplier, ["name"], "");
            const email = get(user, ["email"], "");
            const phone_number = get(supplier, ["phone_number"], "");
            const payment_status = get(
                user,
                ["payment", "account_payment_status"],
                ""
            );
            const deleted = user.deleted
                ? t("accountsTable.columns.deleted")
                : t("accountsTable.columns.active");
            const address = get(supplier, "address", []);
            const payment = get(user, "payment", []);

            return {
                key: supplier.id,
                user: name !== null ? name : "",
                details: "",
                id: user.id,
                email: email !== null ? email : "",
                phone: phone_number !== null ? phone_number : "",
                payment: payment_status !== null ? payment_status : "",
                state: user.validated ? true : false,
                deleted: deleted,
                supervise: null,
                products_count: get(supplier, "products_aggregate.aggregate.count", 0),
                additional_data: {
                    firstname: get(user, "firstname", ""),
                    lastname: get(user, "lastname", ""),

                    city: get(address, "city", ""),
                    address_one: get(address, "address1", ""),
                    address_two: get(address, "address2", ""),
                    zip_code: get(address, "zip_code", ""),

                    payment_id: get(payment, "id", ""),
                    wallet_id: get(payment, "wallet_id", ""),
                    mandate_id: get(payment, "mandate_id", ""),
                    mangopay_id: get(payment, "mangopay_id", ""),
                    iban: get(payment, "iban", ""),

                    siret: get(supplier, "siret", ""),
                    delivery_areas: get(
                        supplier,
                        "delivery_area_suppliers",
                        []
                    ),
                    delivery_days: get(supplier, "delivery_days", []),
                    thresholds: get(supplier, "threshold_suppliers", []),
                    delivery_delay: get(supplier, "delivery_delay", ""),
                    first_delivery_delay: get(
                        supplier,
                        "first_delivery_delay",
                        ""
                    ),
                    delivery_delay_threshold: get(
                        supplier,
                        "delivery_delay_threshold",
                        ""
                    ),
                    manual_order_confirmation: get(
                        supplier,
                        "manual_order_confirmation",
                        ""
                    ),
                    raw: supplier,
                },
            };
        });
    }
);

const suppliersList = createSelector(
    (state) => get(state, [STORE_NAME, "suppliers"], []),
    (suppliers) => {
        if (!suppliers || suppliers.constructor !== Array) return [];

        return suppliers.map((supplier) => {
            const id = get(supplier, ["id"], "");
            const name = get(supplier, ["name"], "");

            return {
                value: id,
                label: name,
            };
        });
    }
);

const count = createSelector(
    (state) => get(state, [STORE_NAME, "count"], []),
    (count) => {
        return count;
    }
);

const loading = createSelector(
    (state) => get(state, [STORE_NAME, "loading"], []),
    (loading) => {
        return loading;
    }
);

const products = createSelector(
    (state) => get(state, [STORE_NAME, "products"], []),
    (products) => {
        return products;
    }
);

const suppliersSelector = {
    suppliers,
    suppliersList,
    count,
    loading,
    products
};

export default suppliersSelector;
