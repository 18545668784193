import { useEffect, useRef, useState } from "react";
import { TagsActions, TagsSelectors } from "stores/tags";
import { useDispatch, useSelector } from "react-redux";
import { Button, Input } from "antd";
import { DataTable } from "ui/components/table";
import { Loader } from "ui/components/loader";
import { t } from "assets";
import { UpsertModal, MODAL_PROPERTIES } from "./UpsertModal";
import { DeleteModal } from "./DeleteModal";
import "./style.scss";

const Color = ({ tagColor }) => {
    return (
        <div>
            <Input type="color" value={tagColor} style={{ pointerEvents: "none"}}/>
        </div>
    )
}

export const Tags = () => {

    const columns = [
        {
            title: t("tagsTable.columns.id"),
            dataIndex: "key",
        },
        {
            title: t("tagsTable.columns.color"),
            dataIndex: "color",
            width: 100,
            render: (key, value) => (<Color tagColor={value.color}/>)
        },
        {
            title: t("tagsTable.columns.name"),
            dataIndex: "name",
        },
        {
            title: t("tagsTable.columns.tooltip"),
            dataIndex: "tooltip",
        },
        {
            title: t("tagsTable.columns.edit"),
            render: (_, value) => (
                <Button onClick={() => upsertModalRef.current.showModal(MODAL_PROPERTIES.EDIT, value)}>
                    {t("tagsTable.columns.edit")}
                </Button>
            )
        },
        {
            title: t("tagsTable.columns.delete"),
            render: (_, value) => (
                <Button onClick={() => deleteModalRef.current.showModal(value)}>
                    {t("tagsTable.columns.delete")}
                </Button>
            )
        },
    ];

    const dispatch = useDispatch();
    const tags = useSelector(TagsSelectors.tags);
    const loading = useSelector(TagsSelectors.loading);

    const [isModalOpen, setDisplayModal] = useState(false);
    const [isDeleteModalOpen, setDeleteModal] = useState(false);

    useEffect(() => {
        dispatch(TagsActions.getTagsRequest());
    }, [dispatch]);

    const loader = {
        spinning: loading,
        indicator: <Loader />,
    }
    const upsertModalRef = useRef();
    const deleteModalRef = useRef();

    return (
        <div>
            <div className="tag-actions">
                <Button
                    className="tag-add-button"
                    onClick={() => upsertModalRef.current.showModal(MODAL_PROPERTIES.NEW)}
                >
                    {t("tagsTable.new")}
                </Button>
            </div>
            <DataTable columns={columns} dataSource={tags} loading={loader} />
            <UpsertModal 
                ref={upsertModalRef}
                isModalOpen={isModalOpen}
                setDisplayModal={setDisplayModal}
            />
            <DeleteModal
                ref={deleteModalRef} 
                isModalOpen={isDeleteModalOpen}
                setDeleteModal={setDeleteModal}
            />
        </div>
    );
}