import { useState, useEffect } from "react";
import { Modal, Space, Tag, Switch, Input, Button, Spin, Alert } from "antd";
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { t } from "assets";
import { get } from "lodash";
import "./style.scss";

const ManageModal = ({
        isModalOpen,
        handleClose,
        categories,
        idCategoryToManage,
        addSubCategory,
        deleteSubCategory,
        loading 
    }) => {

    const [subCategories, setSubCategories] = useState([]);
    const [enableDelete, setEnableDelete] = useState(false);
    const [newSubName, setNewSubName] = useState("");

    useEffect(() => {
        const categoryIdx = categories.findIndex
            ((item) => item?.key === idCategoryToManage);

        if (categoryIdx !== -1 && categories[categoryIdx]) {
            if (categories[categoryIdx]?.sub_categories?.length > 0) {
                const sub = {
                    list: categories[categoryIdx]?.sub_categories,
                    parentName: categories[categoryIdx]?.name
                };
                setSubCategories(sub);
            }
        }
        
    }, [idCategoryToManage, categories]);

    const deleteTagClick = (e, id) => {
        e.preventDefault();
        if (enableDelete)
            deleteSubCategory(id);
    };

    const handleNewSubName = (e) => {
        setNewSubName(e.target.value);
    };

    const addCategory = () => {
        addSubCategory(newSubName);
        setNewSubName("");
    };

    return (
        <Modal
            title={`${t("categoriesTable.manageModal.title")} : ` +
            `${subCategories?.parentName}`}
            open={isModalOpen}
            onCancel={handleClose} 
            footer={null}
        >
            <div>
                <div>
                    <h4>{t("categoriesTable.manageModal.list")}</h4>
                    <div className="sub_categories_delete">
                        <span>
                            {t("categoriesTable.manageModal.showDeleteOptions")}
                        </span>
                        <Switch
                            onChange={setEnableDelete}
                            checked={enableDelete}
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                            label="Suppression"
                        />
                    </div>
                    <div className="sub_categories_alert">
                        {enableDelete && (
                            <Alert
                                message={t("categoriesTable.manageModal.alertDelete")}
                                type="info" 
                            />)}
                    </div>
                    <Spin spinning={loading}>
                        <div className="sub_categories_tags">
                            <Space size={[0, 8]} wrap>
                                {subCategories?.list?.map(sub_cat => {
                                    const id = get(sub_cat, "id", "");
                                    const name = get(sub_cat,"label", "");
                                    const count = get(sub_cat, "products_subcategories_aggregate.aggregate.count", 0);
                                    return (
                                        <Tag
                                            closable={enableDelete && count === 0}
                                            key={id}
                                            onClose={(e) => deleteTagClick(e, id)}
                                        >
                                            {`${name} (${count})`}
                                        </Tag>
                                    );
                                })}
                            </Space>
                        </div>
                    </Spin>
                </div>
                <div>
                    <h4>{t("categoriesTable.manageModal.addOne")}</h4>
                    <Input.Group
                        compact
                        style={{display: 'flex'}}
                    >
                        <Input
                            style={{ marginLeft: 'auto'}}
                            placeholder={t("categoriesTable.manageModal.newCategoryName")}
                            onChange={handleNewSubName}
                            value={newSubName}
                        />
                        <Button
                            type="primary"
                            style={{marginRight: 'auto'}}
                            onClick={addCategory}
                        >
                            {t("categoriesTable.manageModal.confirm")}
                        </Button>
                    </Input.Group>
                </div>
            </div>
        </Modal>
    );
}

export {
    ManageModal
}