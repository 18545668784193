export default {
  getSuppliers: `
      query getSuppliers($offset: Int, $limit: Int, $filter: supplier_informations_bool_exp) {
        supplier_informations(offset : $offset, limit: $limit, where: $filter) {
          id,
          name,
          phone_number,
          manual_order_confirmation,
          order_threshold,
          description,
          delivery_delay,
          first_delivery_delay,
          delivery_delay_threshold,
          siret,
          products_aggregate(where: {activated: {_eq: true}}) {
            aggregate {
              count
            }
          },
          delivery_area_suppliers {
            delivery_area {
              label
            }
          },
          address { 
            city,
            address1,
            address2,
            city,
            zip_code
          },
          delivery_days {
            week_day {
              label
            }
          },
          threshold_suppliers {
            id
            delivery_threshold {
              cost,
              threshold
            }
          },
          users {
            id,
            email,
            validated,
            deleted,
            firstname,
            lastname,
            payment {
              id
              account_payment_status
              wallet_id
              mandate_id
              created_at
              updated_at
              iban
              active
              mangopay_id
              ubo_declarations {
                created_at
                ancien_id
                user_id
                ubos
                reason
                message
              }
              ubo_list_payments {
                ubo_list {
                  city
                  country
                  address1
                  address2
                  postal_code
                  firstname
                  lastname
                  nationality
                  birth_place_country
                }
              }
            }
          }
        },
        supplier_informations_aggregate(where: $filter) {
          aggregate {
            count
          }
        }
      },
    `,
  getSuppliersNames: `
      query getSuppliersNames {
        supplier_informations {
          id,
          name
        }
      }
      `
};
