const mutations = {
  insertMapping: `
    mutation insertOneMapping(
      $data: mapping_catalog_insert_input!
    ) {
      insert_mapping_catalog_one(object: $data) {
          id
          supplier_information {
            name
          }
          sub_category {
            label
            category {
              label
            }
          }
      }
    }
  `,
  updateMapping: `
    mutation updateMapping(
      $id: Int!,
      $data: mapping_catalog_set_input!
    ) {
      update_mapping_catalog(where: {id: {_eq: $id}}, _set: $data) {
        returning {
          id
          supplier_information {
            name
          }
          sub_category {
            label
            category {
              label
            }
          }
        }
      }
    }
  `,
  deleteMapping: `
    mutation deleteMapping(
      $id: Int!
    ) {
      delete_mapping_catalog(where: {id: {_eq: $id}}) {
        returning {
          id
        }
      }
    }
`};

export default mutations;