import { createSelector } from "reselect";
import { get } from "lodash";
const STORE_NAME = "tags";

const tags = createSelector(
    state => get(state, [STORE_NAME, "tags"], []),
    tags => {
        return tags.map(tag => {
            const id = get(tag, ["id"], "");
            const color = get(tag, ["color"], "");
            const name = get(tag, ["label"], "");
            const tooltip = get(tag, ["tooltip"], "");
            return ({
                key: id,
                color: color,
                name: name,
                tooltip: tooltip
            });
        });
    }
);

const loading = createSelector(
    state => get(state, [STORE_NAME, "loading"], []),
    loading => {
        return loading;
    }
);

const selectors =  {
    tags,
    loading,
};

export default selectors;