import { useEffect } from "react";
import { SuppliersActions, SuppliersSelectors } from "stores/suppliers";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { Switch, Button } from "antd";
import { DataTable } from "ui/components/table";
import { Loader } from "ui/components/loader";
import { SearchBar } from "components/SearchBar";
import { t } from "assets";
import { AuthActions } from "stores/auth";
import { DetailsModal } from "./DetailsModal";
import { DownloadModal } from "./DownloadModal";

// TODO : import actions and selectors from index 
export const Suppliers = () => {
    const columns = [
        {
            title: t("accountsTable.columns.user"),
            dataIndex: "user",
            sorter: (a, b) => a.user.localeCompare(b.user)
        },
        {
            title: t("accountsTable.columns.details"),
            dataIndex: "details",
            render: (e, record) => (
                <Button onClick={() => displayDetails(record)}>
                    {t("accountsTable.columns.details")}
                </Button>
            ),
        },
        {
            title: t("accountsTable.columns.id"),
            dataIndex: "id",
            sorter: (a, b) => a.id - b.id
        },
        {
            title: t("accountsTable.columns.email"),
            dataIndex: "email",
            sorter: (a, b) => a.email.localeCompare(b.email),
        },
        {
            title: t("accountsTable.columns.phone"),
            dataIndex: "phone",
            sorter: (a, b) => a.email.localeCompare(b.email)
        },
        {
            title: t("accountsTable.columns.billing"),
            dataIndex: "payment",
            sorter: (a, b) => a.email.localeCompare(b.email)
        },
        {
            title: t("accountsTable.columns.state"),
            dataIndex: "state",
            render: (e, record) => (<Switch onChange={(e) => changeState(record)} checked={record.state} />),

            filters: [
                {
                    text: t("accountsTable.columns.enabled"),
                    value: true,
                },
                {
                    text: t("accountsTable.columns.disabled"),
                    value: false,
                }
            ],
            onFilter: (value, record) => record.state.toString().indexOf(value.toString()) === 0,
        },
        {
            title: t("accountsTable.columns.deleted"),
            dataIndex: "deleted",

            filters: [
                {
                    text: t("accountsTable.columns.deleted"),
                    value: t("accountsTable.columns.deleted"),
                },
                {
                    text: t("accountsTable.columns.active"),
                    value: t("accountsTable.columns.active"),
                }
            ],
            onFilter: (value, record) => record.deleted.indexOf(value) === 0,
        },
        {
            title: t("accountsTable.columns.oversee"),
            dataIndex: "supervise",
            render: (e, record) => (
                <Button onClick={() => overseeAccount(record)}>
                    {t("accountsTable.columns.oversee")}
                </Button>
            )
        },
        {
            title: t("accountsTable.columns.export"),
            dataIndex: "export",
            render: (e, record) => (
                <Button
                    onClick={() => exportProducts(record)}
                    disabled={record?.products_count === 0}
                    style={{ width: "210px" }}
                >
                    {t("accountsTable.columns.export") + " (" + record?.products_count + ")"}
                </Button>
            ),
        }
    ];

    const dispatch = useDispatch();
    const suppliers = useSelector(SuppliersSelectors.suppliers);
    const count = useSelector(SuppliersSelectors.count);
    const loading = useSelector(SuppliersSelectors.loading);

    const [searchValue, setSearch] = useState("");
    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(10);

    const [additionalDataKey, setAdditionalDataKey] = useState(-1);
    const [isModalOpen, setModal] = useState(false);
    const [displayDownload, setDisplayDownload] = useState(false);
    const [supplierToExport, setsupplierToExport] = useState("");

    function displayDetails(details) {
        if (details?.key > -1) {
            setAdditionalDataKey(details.key)
            setModal(true);
        }
    }

    useEffect(() => {
        dispatch(SuppliersActions.getSuppliersRequest(offset, limit, searchValue));
    }, [dispatch, offset, limit, searchValue]);

    const overseeAccount = (record) => {
        dispatch(AuthActions.adminSuperviseRequest(record?.id, {}));
    }

    const exportProducts = (record) => {
        setDisplayDownload(true);
        setsupplierToExport(record);
    }


    const onSearchChange = (e) => {
        setOffset(0);
        setSearch(e)
    }

    const changeState = (record) => {
        dispatch(SuppliersActions.setStatusRequest(record?.id, record?.key, !record?.state))
    }

    const validatePayment = (id) => {
        dispatch(SuppliersActions.validatePaymentRequest(id, additionalDataKey));
    }

    const loader = {
        spinning: loading,
        indicator: <Loader />,
    }

    return (
        <div>
            <SearchBar onChange={onSearchChange} placeholder={t("accountsTable.search")} />
            <DataTable
                columns={columns}
                dataSource={suppliers}
                pagination={{
                    pageSizeOptions: ["5", "10", "25", "100"],
                    showSizeChanger: true,
                    total: count,
                    onChange: (page, pageSize) => {
                        setOffset((page - 1) * pageSize);
                        setLimit(pageSize);
                    }
                }}
                loading={loader}
            />
            <DetailsModal
                suppliers={suppliers}
                additionalDataKey={additionalDataKey}
                validatePayment={validatePayment}
                isModalOpen={isModalOpen}
                handleClose={setModal}
            />
            <DownloadModal
                displayDownload={displayDownload}
                setDisplayDownload={setDisplayDownload}
                supplierToExport={supplierToExport}
                setSupplierToExport={setsupplierToExport}
            />
        </div>
    );
}