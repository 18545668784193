import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
    Modal,
    Input,
    Button,
    Select,
    Form,
    DatePicker,
    AutoComplete,
} from "antd";
import { t } from "assets";
import { useDispatch } from "react-redux";
import { SuppliersSelectors } from "stores/suppliers";
import { SuppliersActions } from "stores/suppliers";
import { MutualisationsActions } from "stores/mutualisations";
import "./style.scss";
import { getFullAddress } from "utils/helpers/addresses";
const axios = require("axios");

const delays = [
    {
        value: "1",
        label: "J + 1",
    },
    {
        value: "2",
        label: "J + 2",
    },
    {
        value: "3",
        label: "J + 3",
    },
    {
        value: "4",
        label: "J + 4",
    },
    {
        value: "5",
        label: "J + 5",
    },
];

async function fetchAddresses(searchInput) {
    try {
        let res = await axios({
            url:
                `${process.env.REACT_APP_GEOLOC_API_URL}` +
                `/search/?q=${searchInput}&limit=15`,
            method: "get",
            withCredentials: false,
        });
        if (res.status === 200) {
            return res.data;
        }
    } catch (err) {
        console.error(err);
    }
}

const ManageModal = ({ isModalOpen, handleClose }) => {
    const [formData, setFormData] = useState({});
    const dispatch = useDispatch();
    const suppliers = useSelector(SuppliersSelectors.suppliersList);

    useEffect(() => {
        dispatch(SuppliersActions.getSuppliersNamesRequest());
    }, [dispatch]);

    const onChangeFormData = (key, value) => {
        setFormData({ ...formData, [key]: value });
    };

    const onChangeSupplier = (id, name) => {
        setFormData({ ...formData, supplier_id: id, supplier_name: name });
    };

    const [addressList, setAddressList] = useState([]);

    const onSearchAddress = async (searchInput) => {
        const response = await fetchAddresses(searchInput);
        const addresses = [];

        if (
            response &&
            response?.features &&
            Array.isArray(response.features)
        ) {
            response.features.forEach((item) => {
                addresses.push({
                    value: item.properties.label,
                    text: item.properties.label,
                    name: item.properties.name,
                    city: item.properties.city,
                    zip_code: item.properties.postcode,
                });
            });
        }
        setAddressList(addresses);
    };

    const onFormSubmit = (values) => {
        let data = {
            id_supplier: formData.supplier_id,
            supplier: formData.supplier_name,
            end_date: formData.endMutualisation.toISOString(),
            delay: formData.formDelay,
            threshold: formData.threshold,
            dispatch_fees: formData.dispatchFees,
            address1: formData.address1,
            address2: formData.address2 ?? "",
            city: formData.city,
            zip_code: formData.zip_code,
            full_address: getFullAddress({
                address1: formData.address1,
                address2: formData.address2 ?? "",
                city: formData.city,
                zip_code: formData.zip_code,
            }),
        };
        dispatch(MutualisationsActions.insertMutualisationRequest(data));
        handleClose();
    };

    const onChangeAddress = (newValue: string, v) => {
        if (!addressList || !Array.isArray(addressList)) return;
        addressList.forEach((item) => {
            if (item.value === newValue) {
                setFormData({
                    ...formData,
                    address: item.value,
                    address1: item.name,
                    city: item.city,
                    zip_code: item.zip_code,
                });
            }
        });
    };

    return (
        <Modal
            title={t("mutualisationTable.form.title")}
            open={isModalOpen}
            onCancel={handleClose}
            footer={null}
            destroyOnClose={true}
        >
            <div className="mutualisation_form">
                <Form
                    onFinish={onFormSubmit}
                    name="mutualisation-product-form"
                    key={"mutualisation-product-form"}
                    layout="vertical"
                    preserve={false}
                    initialValues={{
                        "mutualisation-form-supplier_name": "",
                        "mutualisation-form-end-mutualisation": "",
                        "mutualisation-form-delay": "",
                        "mutualisation-form-threshold": "",
                        "mutualisation-form-fees": "",
                        "mutualisation-form-address": "",
                        "mutualisation-form-address2": "",
                    }}
                >
                    <Form.Item
                        name={"mutualisation-form-supplier_name"}
                        key={"mutualisation-form-supplier_name"}
                        label={t("mutualisationTable.form.supplierName")}
                        rules={[
                            {
                                required: true,
                                message: t(
                                    "mutualisationTable.form.errors.mandatorySupplier"
                                ),
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            onChange={(_, e) => {
                                onChangeSupplier(e.value, e.label);
                            }}
                            options={suppliers}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                (option?.label ?? "")
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                            }
                            placeholder={t(
                                "mutualisationTable.form.supplierNamePlaceholder"
                            )}
                            value={formData.supplier_name}
                            key={formData.supplier_name}
                        />
                    </Form.Item>
                    <Form.Item
                        name={"mutualisation-form-end-mutualisation"}
                        key={"mutualisation-form-end-mutualisation"}
                        label={t("mutualisationTable.form.endMutualisation")}
                        rules={[
                            {
                                required: true,
                                message: t(
                                    "mutualisationTable.form.errors.mandatoryEndMutualisation"
                                ),
                            },
                        ]}
                    >
                        <DatePicker
                            format={"DD/MM/YYYY"}
                            onChange={(value) =>
                                onChangeFormData("endMutualisation", value)
                            }
                            value={formData.endMutualisation}
                        />
                    </Form.Item>
                    <Form.Item
                        name={"mutualisation-form-delay"}
                        key={"mutualisation-form-delay"}
                        label={t("mutualisationTable.form.deliveryDelay")}
                        rules={[
                            {
                                required: true,
                                message: t(
                                    "mutualisationTable.form.errors.mandatoryDeliveryDelay"
                                ),
                            },
                        ]}
                    >
                        <Select
                            options={delays}
                            placeholder={t(
                                "mutualisationTable.form.deliveryDelayPlaceholder"
                            )}
                            onChange={(value) =>
                                onChangeFormData("formDelay", value)
                            }
                            value={formData.formDelay}
                        />
                    </Form.Item>
                    <Form.Item
                        key={"mutualisation-form-threshold"}
                        name={"mutualisation-form-threshold"}
                        label={t("mutualisationTable.form.threshold")}
                        rules={[
                            {
                                required: true,
                                message: t(
                                    "mutualisationTable.form.errors.mandatoryThreshold"
                                ),
                            },
                        ]}
                    >
                        <Input
                            placeholder={t("mutualisationTable.form.threshold")}
                            type="number"
                            onChange={(e) =>
                                onChangeFormData("threshold", e.target.value)
                            }
                        />
                    </Form.Item>
                    <Form.Item
                        name={"mutualisation-form-fees"}
                        key={"mutualisation-form-fees"}
                        label={t("mutualisationTable.form.dispatchFees")}
                        rules={[
                            {
                                required: true,
                                message: t(
                                    "mutualisationTable.form.errors.mandatoryDispatchFees"
                                ),
                            },
                        ]}
                    >
                        <Input
                            placeholder={t(
                                "mutualisationTable.form.dispatchFeesPlaceholder"
                            )}
                            type="number"
                            onChange={(e) =>
                                onChangeFormData("dispatchFees", e.target.value)
                            }
                        />
                    </Form.Item>
                    <Form.Item
                        name={"mutualisation-form-address"}
                        label={t("mutualisationTable.form.address")}
                        key={"mutualisation-form-address"}
                        rules={[
                            {
                                required: true,
                                message: t(
                                    "mutualisationTable.form.errors.mandatoryAddress"
                                ),
                            },
                        ]}
                    >
                        <AutoComplete
                            showSearch
                            value={formData.address}
                            placeholder={t(
                                "mutualisationTable.form.addressPlaceholder"
                            )}
                            defaultActiveFirstOption={false}
                            showArrow={false}
                            filterOption={false}
                            allowClear={true}
                            onSearch={onSearchAddress}
                            onChange={(key, value) =>
                                onChangeAddress(key, value)
                            }
                            options={(addressList || []).map((d) => ({
                                value: d.value,
                                label: d.text,
                            }))}
                        />
                    </Form.Item>
                    <Form.Item
                        key={"mutualisation-form-address2"}
                        name={"mutualisation-form-address2"}
                        label={t("mutualisationTable.form.address2")}
                        value={formData.address2}
                    >
                        <Input
                            placeholder={t(
                                "mutualisationTable.form.address2Placeholder"
                            )}
                            type="text"
                            onChange={(e) =>
                                onChangeFormData("address2", e.target.value)
                            }
                            value={formData.address2}
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            {t("mutualisationTable.form.save")}
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </Modal>
    );
};

export { ManageModal };
