import { createAction } from "utils/redux";

// Types
export const types = {
    GET_CATEGORIES_REQUEST: "categories_GET_CATEGORIES_REQUEST",
    GET_CATEGORIES_SUCCESS: "categories_GET_CATEGORIES_SUCCESS",
    GET_CATEGORIES_FAILURE: "categories_GET_CATEGORIES_FAILURE",

    ADD_SUB_CATEGORY_REQUEST: "categories_ADD_SUB_CATEGORY_REQUEST",
    ADD_SUB_CATEGORY_SUCCESS: "categories_ADD_SUB_CATEGORY_SUCCESS",
    ADD_SUB_CATEGORY_FAILURE: "categories_ADD_SUB_CATEGORY_FAILURE",

    DELETE_SUB_CATEGORY_REQUEST: "categories_DELETE_SUB_CATEGORY_REQUEST",
    DELETE_SUB_CATEGORY_SUCCESS: "categories_DELETE_SUB_CATEGORY_SUCCESS",
    DELETE_SUB_CATEGORY_FAILURE: "categories_DELETE_SUB_CATEGORY_FAILURE",
};

// Actions
const getCategoriesRequest = () =>
    createAction(types.GET_CATEGORIES_REQUEST);
const getCategoriesSuccess = (categories) =>
    createAction(types.GET_CATEGORIES_SUCCESS, { categories });
const getCategoriesFailure = () =>
    createAction(types.GET_CATEGORIES_FAILURE);

const addSubCategoryRequest = (subCategory) =>
    createAction(types.ADD_SUB_CATEGORY_REQUEST, { subCategory });
const addSubCategorySuccess = (idInserted, subCategory) =>
    createAction(types.ADD_SUB_CATEGORY_SUCCESS, { idInserted, subCategory });
const addSubCategoryFailure = () =>
    createAction(types.ADD_SUB_CATEGORY_FAILURE)

const deleteSubCategoryRequest = (idToDelete, idParent) =>
    createAction(types.DELETE_SUB_CATEGORY_REQUEST, { idToDelete, idParent });
const deleteSubCategorySuccess = (idDeleted, idParent) =>
    createAction(types.DELETE_SUB_CATEGORY_SUCCESS, { idDeleted, idParent });
const deleteSubCategoryFailure = () =>
    createAction(types.DELETE_SUB_CATEGORY_FAILURE);

const actions = {
    getCategoriesRequest,
    getCategoriesSuccess,
    getCategoriesFailure,

    addSubCategoryRequest,
    addSubCategorySuccess,
    addSubCategoryFailure,

    deleteSubCategoryRequest,
    deleteSubCategorySuccess,
    deleteSubCategoryFailure
}

export default actions;