import { useEffect } from "react";
import { RestaurantsActions, RestaurantsSelectors } from "stores/restaurants";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { Switch, Button } from "antd";
import { DataTable } from "ui/components/table";
import { Loader } from "ui/components/loader";
import { SearchBar } from "components/SearchBar";
import { t } from "assets";
import { AuthActions } from "stores/auth";
import { DetailsModal } from "./DetailsModal";


// TODO : import actions and selectors from index 
export const Restaurants = () => {
    const columns = [
        {
            title: t("accountsTable.columns.user"),
            dataIndex: "user",
            sorter: (a, b) => a.user.localeCompare(b.user)
        },
        {
            // TODO: add details button
            title: t("accountsTable.columns.details"),
            dataIndex: "details",
            render: (e, record) => (<Button onClick={() => displayDetails(record)}>{t("accountsTable.columns.details")}</Button>),
        },
        {
            title: t("accountsTable.columns.id"),
            dataIndex: "id",
            sorter: (a, b) => a.id - b.id
        },
        {
            title: t("accountsTable.columns.email"),
            dataIndex: "email",
            sorter: (a, b) => a.email.localeCompare(b.email),
        },
        {
            title: t("accountsTable.columns.phone"),
            dataIndex: "phone",
            sorter: (a, b) => a.email.localeCompare(b.email)
        },
        {
            title: t("accountsTable.columns.billing"),
            dataIndex: "payment",
            sorter: (a, b) => a.email.localeCompare(b.email)
        },
        {
            title: t("accountsTable.columns.state"),
            dataIndex: "state",
            render: (e, record) => (<Switch onChange={(e) => handleStateChange(record)} checked={record.state} />),
    
            filters: [
                {
                    text: t("accountsTable.columns.enabled"),
                    value: true,
                },
                {
                    text: t("accountsTable.columns.disabled"),
                    value: false,
                }
            ],
            onFilter: (value, record) => record.state.toString().indexOf(value.toString()) === 0,
        },
        {
            title: t("accountsTable.columns.deleted"),
            dataIndex: "deleted",
    
            filters: [
                {
                    text: t("accountsTable.columns.deleted"),
                    value: t("accountsTable.columns.deleted"),
                },
                {
                    text: t("accountsTable.columns.active"),
                    value: t("accountsTable.columns.active"),
                }
            ],
            onFilter: (value, record) => record.deleted.indexOf(value) === 0,
        },
        {
            title: t("accountsTable.columns.oversee"),
            dataIndex: "supervise",
            render: (e, record) => (<Button onClick={() => handleOversee(record)}>{t("accountsTable.columns.oversee")}</Button>)
        }
    ];

    const dispatch = useDispatch();
    const restaurants = useSelector(RestaurantsSelectors.restaurants);
    const count = useSelector(RestaurantsSelectors.count);
    const loading = useSelector(RestaurantsSelectors.loading);

    const [searchValue, setSearch] = useState("");
    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(10);

    const [additionalDataKey, setAdditionalDataKey] = useState(-1);
    const [isModalOpen, setModal] = useState(false);

    function displayDetails(details) {
        if (details?.key > -1) {
            setAdditionalDataKey(details.key)
            setModal(true);
        }
    }

    useEffect(() => {
        dispatch(RestaurantsActions.getRestaurantsRequest(offset, limit, searchValue));
    }, [dispatch, offset, limit, searchValue]);

    const onSearchChange = (e) => {
        setOffset(0);
        setSearch(e)
    }

    const handleOversee = (record) => {
        dispatch(AuthActions.adminSuperviseRequest(record?.id, {}));
    }

    const validatePayment = (id) => {
        dispatch(RestaurantsActions.validatePaymentRequest(id, additionalDataKey));
    }

    const handleStateChange = (record) => {
        dispatch(RestaurantsActions.setStatusRequest(record?.id, record?.key, !record?.state ))
    }

    const loader = {
        spinning: loading,
        indicator: <Loader />,
    }

    return (
        <div>
            <SearchBar onChange={onSearchChange} placeholder={t("accountsTable.search")} />
            <DataTable columns={columns} dataSource={restaurants}
            pagination={{
                pageSizeOptions: ["5", "10", "25", "100"],
                showSizeChanger: true,
                total: count,
                onChange: (page, size) => {
                    setOffset((page - 1) * size);
                    setLimit(size);
                },
            }}
            loading={loader}
            />
            <DetailsModal
                restaurants={restaurants}
                additionalDataKey={additionalDataKey}
                isModalOpen={isModalOpen}
                handleClose={setModal}
                validatePayment={validatePayment}
            />
        </div>
    );
}