import { call, put, takeLatest, delay } from "redux-saga/effects";
import { default as AuthActions, types } from "./actions";
import { AuthenticationServices } from "services"
import { encode } from "base-64";
import { get } from "lodash";
import { UserActions } from "../user";
import history from "stores/history";
import { showError } from "utils/helpers/notifications"
import { CommonActions } from "../common";
import { ROUTES } from "core/router";

const CryptoJS = require("crypto-js");
const KEY = process.env.REACT_APP_ADMIN_KEY;
const SUPERVISE_URL = process.env.REACT_APP_SUPERVISE_URL;

function* logout() {
    yield takeLatest(types.LOG_OUT_REQUEST, logOutSaga);
}

function* getAuthToken(username, password, code) {
    const [error, response] = yield call(
        AuthenticationServices.getToken,
        username,
        password,
        code
    );
    const status = get(response, "status", 500);
    let userType = "";
    if (!error && status === 200) {
        const { access_token, token_type, refresh_token, type } = response.data;
        const authorization = encode(username + ":" + password);

        if (type === 'Admin') {
            yield put(
                AuthActions.loginSuccess({
                    token_type,
                    token: access_token,
                    refresh_token,
                    authorization,
                    username,
                    type
                })
            );
        }
        else {
            showError('login.error.notAdmin');
        }
    }
    else {
        showError('login.error.wrongCredentials');
    }
    return {
        status,
        userType
    };
}

function* adminSupervise() {
    yield takeLatest(types.ADMIN_SUPERVISE, adminSuperviseSaga);
}

function* adminSuperviseSaga({ idUserToSupervise }) {
    try {
        const [error, response] = yield call(
            AuthenticationServices.supervise,
            idUserToSupervise
        );
        const data = get(response, "data", null);
        if (error || !response || !data)
            throw "invalid api return";
        const dataToSend = {
            token: get(data, ["accessToken", "value"], ""),
            refresh_token: get(data, ["accessToken", "refresh_token"], ""),
            type: get(data, ["user", "type"], ""),
            email: get(data, ["user", "email"], ""),
        };
        const encodedTokens = 
            CryptoJS.AES.encrypt(
                JSON.stringify(dataToSend),
                KEY
            ).toString();
        const url = `${SUPERVISE_URL}${encodeURIComponent(encodedTokens)}`;
        window.open(url, '_blank');
    } catch (error) {
        showError('login.error.errorOversee');
        console.log(error);
    }
}

function* login({ username, password }) {

    try {
        const [error, response] = yield call(
            AuthenticationServices.login,
            username,
            password
        );
        const status = get(response, "status", 500);
        if (error || status !== 200) throw new Error("invalid code api response");

        const url = new URL(get(response, ["request", "responseURL"], ""));
        const urlParams = new URLSearchParams(url.search);
        const code = urlParams.get("code");

        const { status: tokenStatus, userType } = yield call(
            getAuthToken,
            username,
            password,
            code
        );
        if (tokenStatus !== 200) throw new Error("invalid api status");

        yield put(UserActions.getCurrentLoginRequest(true, userType));
        yield call(history.push, "/")
    } catch (error) {
        showError('login.error.wrongCredentials');
        yield delay(3000);
    }

}

function* logOutSaga() {
    yield put(CommonActions.resetAllReducers());
    yield call(history.push, ROUTES.LOGIN);
}

export default [
    takeLatest(types.LOGIN_REQUEST, login),
    logout(),
    adminSupervise()
];

