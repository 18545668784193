const queries = {
    getMutualisations: `
  query getMutualisations($filter: mutualisations_bool_exp, $offset: Int, $limit: Int, $order: [mutualisations_order_by!]) {
    mutualisations(offset: $offset, limit: $limit, where: $filter, order_by: $order) {
      id
      delivery_date
      end_date
      threshold
      dispatch_fees
      mutualisation_status {
        label
      }
      mutualisations_address {
        address1,
        address2,
        city,
        zip_code
      }
      supplier_information {
        id
        name
      }
    }
    mutualisations_aggregate(where: $filter) {
      aggregate {
        count
      }
    },
    mutualisation_status(where: {label: {_eq: "Created"}}) {
      id
    }
  }
  `,
    getAddress: `
  query getAddress($filter: addresses_bool_exp) {
    addresses(where: $filter) {
      id
    }
  }
  `,
};

export default queries;
