import { useState, useImperativeHandle, forwardRef } from "react";
import { SpecsActions } from "stores/specs";
import { useDispatch } from "react-redux";
import { Modal } from "antd";
import { t } from "assets";
import { get } from "lodash";

const DeleteModalComponent = ({ isModalOpen, setDeleteModal }, ref) => {

    const dispatch = useDispatch();
    const [idSpecToDelete, setIdSpec] = useState(null);

    useImperativeHandle(ref, () => ({
        showModal(value)  {
            const id = get(value, "key", null);
            setIdSpec(id);
            setDeleteModal(true);
        }
    }));

    const handleClose = () => {
        setDeleteModal(false);
    };

    const handleConfirm = () => {
        dispatch(SpecsActions.deleteSpecRequest(idSpecToDelete))
        setDeleteModal(false);
    };

    return (
        <Modal
            title={t("specsTable.deleteModal.title")}
            open={isModalOpen}
            onCancel={handleClose} 
            onOk={handleConfirm}
            destroyOnClose
        >
            <div>
                <p>{t("specsTable.deleteModal.confirmDelete")}</p>
            </div>
        </Modal>
    );
};

const DeleteModal = forwardRef(DeleteModalComponent);

export {
    DeleteModal
}