import { authenticatedQuery } from './middlewares';
import { restaurantQueries } from './graphQLRequests';
import { restaurantMutations } from './graphQLRequests';

const getRestaurants = (offset, limit, filter) =>
    authenticatedQuery(restaurantQueries.getRestaurants, {}, { offset, limit, filter });
const validatePayment = (id) =>
    authenticatedQuery(restaurantMutations.validatePayment, {}, { id });
const setStatus = (id, status) =>
    authenticatedQuery(restaurantMutations.setStatus, {}, { id, status });

export default {
    getRestaurants,
    validatePayment,
    setStatus,
}