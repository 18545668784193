import { createAction } from "utils/redux";

// Types
export const types = {
    GET_SPECS_REQUEST: "specs_GET_SPECS_REQUEST",
    GET_SPECS_SUCCESS: "specs_GET_SPECS_SUCCESS",
    GET_SPECS_FAILURE: "specs_GET_SPECS_FAILURE",

    UPSERT_SPEC_REQUEST: "specs_UPSERT_SPEC_REQUEST",
    UPSERT_SPEC_SUCCESS: "specs_UPSERT_SPEC_SUCCESS",
    UPSERT_SPEC_FAILURE: "specs_UPSERT_SPEC_FAILURE",

    DELETE_SPEC_REQUEST: "specs_DELETE_SPEC_REQUEST",
    DELETE_SPEC_SUCCESS: "specs_DELETE_SPEC_SUCCESS",
    DELETE_SPEC_FAILURE: "specs_DELETE_SPEC_FAILURE",
};

// Actions
const getSpecsRequest = (searchValue) => createAction(types.GET_SPECS_REQUEST, { searchValue });
const getSpecsSuccess = (specs) => createAction(types.GET_SPECS_SUCCESS, { specs });
const getSpecsFailure = () => createAction(types.GET_SPECS_FAILURE);

const upsertSpecRequest = (specId, data) => createAction(types.UPSERT_SPEC_REQUEST, { specId, data });
const upsertSpecSuccess = (spec, specId) => createAction(types.UPSERT_SPEC_SUCCESS, { spec, specId });
const upsertSpecFailure = () => createAction(types.UPSERT_SPEC_FAILURE)

const deleteSpecRequest = (specId) => createAction(types.DELETE_SPEC_REQUEST, { specId });
const deleteSpecSuccess = (specId) => createAction(types.DELETE_SPEC_SUCCESS, { specId });
const deleteSpecFailure = () => createAction(types.DELETE_SPEC_FAILURE);

const actions = {
    getSpecsRequest,
    getSpecsSuccess,
    getSpecsFailure,

    upsertSpecRequest,
    upsertSpecSuccess,
    upsertSpecFailure,

    deleteSpecRequest,
    deleteSpecSuccess,
    deleteSpecFailure
}

export default actions;