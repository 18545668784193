import { Modal } from "antd";
import "./style.scss";
import { MappingSuppliersActions } from "stores/supplier_mapping";
import { t } from "assets";
import { useDispatch } from "react-redux";

const DeleteModal = ({
        isModalOpen,
        handleClose,
        selectedProduct
    }) => {

    const dispatch = useDispatch();

    return (
        <Modal
            title={t("mappingSuppliersTable.form.deleteTitle")}
            open={isModalOpen}
            onCancel={handleClose} 
            destroyOnClose={true}
            onOk={() => {
                dispatch(MappingSuppliersActions.deleteSupplierMappingRequest(selectedProduct.id));
                handleClose();
            }}
        >
            <div className="categories_selector">
                <p>{t("mappingSuppliersTable.form.confirmDelete")}</p>
            </div>
        </Modal>
    );
}

export {
    DeleteModal
}