import { get, set } from "lodash";
import { types } from "./actions";

const initialState = {
    specs: [],
    loading: false
};

function specsReducer(state, action) {
    if (typeof state === "undefined") return initialState;

    const type = get(action, "type", "");
    switch (type) {
        case types.GET_SPECS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.GET_SPECS_SUCCESS: {
            const specs = get(action, "specs", {});
            return {
                ...state,
                specs: specs,
                loading: false,
            };
        };
        case types.GET_SPECS_FAILURE:
            return {
                ...state,
                specs : [],
                loading: false,
            };
        case types.UPSERT_SPEC_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.UPSERT_SPEC_SUCCESS: {
            const specs = [...state.specs]  
            const idUpdatedSpec = get(action, "specId", null);
            const newSpec = get(action, "spec", {});
            const indexUpdated = specs.findIndex((spec => spec.id === idUpdatedSpec));

            if (indexUpdated !== -1) {
                set(newSpec, "id", idUpdatedSpec);
                set(specs, [indexUpdated], newSpec);
            } else {
                set(newSpec, "id", idUpdatedSpec);
                specs.push(newSpec);
            }
            return {
                ...state,
                loading: false,
                specs: specs
            };
        }
        case types.DELETE_SPEC_FAILURE: {
            return {
                ...state,
                loading: false,
            };
        }
        case types.DELETE_SPEC_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.DELETE_SPEC_SUCCESS:
            const specs = [...state.specs]  
            const idRemovedSpec = get(action, "specId", null);
            const indexRemoved = specs.findIndex((spec => spec.id === idRemovedSpec));
            if (idRemovedSpec && indexRemoved !== -1)
                specs.splice(indexRemoved, 1);
            return {
                ...state,
                loading: false,
                specs: specs
            };
        case "":
        default:
            return state;
    }
}

export default specsReducer;
