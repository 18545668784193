import { useState, useImperativeHandle, forwardRef } from "react";
import { TagsActions } from "stores/tags";
import { useDispatch } from "react-redux";
import { Modal } from "antd";
import { t } from "assets";
import { Input, Form, Button } from 'antd';
import { get } from "lodash";
import "./style.scss";

const { TextArea } = Input;

const MODAL_PROPERTIES = {
    EDIT: 'edit_tag',
    EDIT_TITLE:  t("tagsTable.upsertModal.editLabel"),

    NEW: 'new_tag',
    NEW_TITLE:  t("tagsTable.upsertModal.createLabel"),

    DEFAULT_LABEL_NAME: 'statut',
    DEFAULT_LABEL_COLOR: '#000000'
};

const UpsertModalComponent = ({ isModalOpen, setDisplayModal }, ref) => {
    const [modalTitle, setModalTitle] = useState("");

    const [tagId, setTagId] = useState(null);
    const [newTagName, setTagName] = useState("");
    const [newTagColor, setTagColor] = useState("");
    const [newTagTip, setTagTip] = useState("");

    const dispatch = useDispatch();

    useImperativeHandle(ref, () => ({
        showModal(type, value)  {
            if (type === MODAL_PROPERTIES.EDIT) {
                setModalTitle(MODAL_PROPERTIES.EDIT_TITLE);
                setTagId(get(value, "key", ""));
                setTagColor(get(value, "color", ""));
                setTagName(get(value, "name", ""));
                setTagTip(get(value, "tooltip", ""));
            } else {
                setTagId(null);
                setModalTitle(MODAL_PROPERTIES.NEW_TITLE)
                setTagName(MODAL_PROPERTIES.DEFAULT_LABEL_NAME)
                setTagColor(MODAL_PROPERTIES.DEFAULT_LABEL_COLOR);
                setTagTip("");
            }
            setDisplayModal(true);
        }
    }));

    const Overview = () => {
        return (
            <div className="tag-overview">
                <h3>{t("tagsTable.upsertModal.overview")}</h3>
                <div
                    className="negotiated-price-tag"
                    style={{backgroundColor: newTagColor}}
                >
                    <p>{newTagName}</p>
                </div>
                <div
                    className="negotiated-price-tag negotiated-price-tag-mb"
                    style={{backgroundColor: newTagColor}}
                >
                    <p>{newTagName}</p>
                </div>
            </div>
        );
    }

    const onFormSubmit = (data: any) => {
        dispatch(TagsActions.upsertTagRequest(tagId, data));
        setDisplayModal(false);
    };

    const handleClose = () => {
        setDisplayModal(false);
    };

    return (
        <Modal
            title={modalTitle}
            open={isModalOpen}
            footer={null} 
            maskClosable={false}
            onCancel={handleClose}
            destroyOnClose
        >
            <Overview />
            <Form
                name="tag-form"
                layout="vertical"
                className="tag-form"
                onFinish={onFormSubmit}
                initialValues={{
                    "tag-form-name": newTagName,
                    "tag-form-color": newTagColor,
                    "tag-form-tooltip": newTagTip
                }}
                preserve={false}
            >
                <Form.Item
                    name="tag-form-name"
                    label={t("tagsTable.upsertModal.form.nameLabel")}
                    rules={[
                        {
                            async validator(_, value) {
                                if (!value || value.length === 0) {
                                    return Promise.reject(new Error(t("tagsTable.upsertModal.form.warningName")));
                                }
                                return Promise.resolve();
                            }, required: true
                        },
                    ]}
                >
                    <Input onChange={(e) => setTagName(e.target.value)}/>
                </Form.Item>
                <Form.Item
                    name="tag-form-color" label={t("tagsTable.upsertModal.form.colorLabel")}
                    rules={[{ required: true, message: t("tagsTable.upsertModal.warningColor") }]}
                >
                    <Input type="color" value={newTagColor} onChange={(e) => setTagColor(e.target.value)}/>
                </Form.Item>
                <Form.Item
                    name="tag-form-tooltip"
                    label={t("tagsTable.upsertModal.form.tooltipLabel")}
                >
                    <TextArea rows={3} maxLength={64} onChange={(e) => setTagTip(e.target.value)} value={newTagTip}/>
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        {t("tagsTable.upsertModal.form.save")}
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};

const UpsertModal = forwardRef(UpsertModalComponent);

export  {
    UpsertModal,
    MODAL_PROPERTIES
}
