const getRestaurants = `
      query getRestaurants($offset: Int, $limit: Int, $filter: restaurants_bool_exp) {
        restaurants(offset : $offset, limit: $limit, where: $filter)  {
          id,
          name,
          phone_number,
          accept_equivalent_products,
          address { 
            city,
            address1,
            address2,
            zip_code
          },
          manage_roles {
            user {
              id,
              email,
              validated,
              deleted,
              firstname,
              lastname,
              payment {
                id
                account_payment_status
                wallet_id
                mandate_id
                created_at
                updated_at
                iban
                active
                mangopay_id
                ubo_declarations {
                  created_at
                  ancien_id
                  user_id
                  ubos
                  reason
                  message
                }
                ubo_list_payments {
                  ubo_list {
                    city
                    country
                    address1
                    address2
                    postal_code
                    firstname
                    lastname
                    nationality
                    birth_place_country
                  }
                }
              }
            }
          }
        },
        restaurants_aggregate(where: $filter) {
          aggregate {
            count
          }
        }
      },
    `;

export default {
  getRestaurants
};