import { Button, Modal, Checkbox } from "antd";
import { CSVLink } from "react-csv";
import { SuppliersActions } from "stores/suppliers";
import { t } from "assets";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SuppliersSelectors } from "stores/suppliers";

export const DownloadModal = ({
    displayDownload,
    setDisplayDownload,
    supplierToExport,
    setSupplierToExport
}) => {

    const products = useSelector(SuppliersSelectors.products);
    const dispatch = useDispatch();
    const closeModal = () => {
        setDisplayDownload(false);
        setSupplierToExport("");
        dispatch(SuppliersActions.exportProductsClear());
    }
    const [showMissingPictures, setShowMissingPictures] = useState(false);
    const [csv, setCsv] = useState([]);

    const exportProducts = () => {
        dispatch(SuppliersActions.exportProductsRequest(supplierToExport.key));
    }

    const cleanString = (string) => {
        let cleanString = '';
        if (string && string?.length > 0) {
            cleanString = string.replace(/;/g, ',');
            return cleanString.replace(/[&/\\#+()$~%.'":*?<>{}\r\n|\n|\r]/g, '');
        }
        return '';
    }

    useEffect(() => {
        let csvSheet = [
            [
                '*Référence produit (unique)',
                '*Nom',
                '*Prix (HT)',
                '*Nombre d\'unités',
                '*Prix par unité (calcul automatique)',
                '*Unité',
                '*Catégorie',
                '*Sous-catégorie',
                'Marques',
                'Caractéristiques',
                'Origines',
                '* Taux de TVA (%)',
                'Description',
                'Unité de vente',
                'Information importante (optionnel)',
                '" Images ID (Le nom de votre fichier, sans l\'extension "".jpg, .png…"")"',
                'Statut'
            ]
        ];
        showMissingPictures && csvSheet[0].push('Image attachée');
        if (products?.length > 0) {
            products.forEach((product, index) => {
                csvSheet.push([
                    product?.product_id,
                    product?.name,
                    product?.price,
                    product?.packaging,
                    product?.unit_price,
                    product?.unit_type,
                    product?.category,
                    product?.sub_category,
                    product?.brand,
                    product?.spec_labels,
                    cleanString(product?.origin),
                    product?.vat_rate,
                    cleanString(product?.description),
                    product?.sale_unit,
                    cleanString(product?.important_information),
                    product?.filename,
                    product?.tags_list,
                ]);
                if (showMissingPictures) {
                    csvSheet[index + 1].push(product?.image_attached === "OK" ? "Oui" : "Non");
                }
            });
            setCsv(csvSheet);
        }
    }, [products, showMissingPictures]);

    return (
        <Modal
            title={t("accountsTable.downloadModal.title")}
            open={displayDownload}
            onCancel={closeModal}
            footer={null}
            closable={true}
        >
            {products?.length === 0 && supplierToExport?.key ? (
                <div>
                    <div style={{ paddingBottom: '15x' }}>
                        <Checkbox onChange={(e) => setShowMissingPictures(e.target.checked)} checked={showMissingPictures}>
                            {t("accountsTable.downloadModal.missingPictures")}
                        </Checkbox>
                    </div>
                    <div>
                        <Button onClick={exportProducts}>
                            {t("accountsTable.downloadModal.generateFile")}
                        </Button>
                    </div>
                </div>
            ) : (
                <div>
                    <CSVLink
                        data={csv}
                        separator={';'}
                        enclosingCharacter={``}
                        filename={`EXPORT_PRODUITS_${supplierToExport.user}.csv`}
                        onClick={closeModal}
                    >
                        {t("accountsTable.downloadModal.download")}
                    </CSVLink>
                </div>
            )}
        </Modal>
    )
}