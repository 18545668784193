import { get, set } from "lodash";
import { types } from "./actions";
import { getFullAddress } from "utils/helpers/addresses";
const { DateTime } = require("luxon");

const initialState = {
    mutualisations: [],
    loading: false,
    count: 0,
    createdStatusId: -1,
};

function MutualisationsReducer(state, action) {
    if (typeof state === "undefined") return initialState;

    const type = get(action, "type", "");
    switch (type) {
        case types.GET_MUTUALISATIONS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.GET_MUTUALISATIONS_SUCCESS: {
            const mutualisations = get(action, "mutualisations", {});
            const count = get(action, "count", {});
            const createdStatusId = get(action, "createdStatusId", {});

            let mutualisationsArray = [];

            mutualisationsArray = mutualisations.map((item) => {
                const delivery_date = DateTime.fromISO(
                    get(item, ["delivery_date"], "")
                )
                    .setZone("Europe/Paris")
                    .toFormat("dd/LL/yyyy");

                const end_date = DateTime.fromISO(get(item, ["end_date"], ""))
                    .setZone("Europe/Paris")
                    .toFormat("dd/LL/yyyy HH:mm");

                return {
                    key: get(item, ["id"], ""),
                    delivery_date: delivery_date,
                    end_date: end_date,
                    threshold: get(item, ["threshold"], ""),
                    supplier: get(item, ["supplier_information", "name"], ""),
                    address: getFullAddress(
                        get(item, ["mutualisations_address"], [])
                    ),
                    dispatch_fees: get(item, ["dispatch_fees"], ""),
                    status: get(item, ["mutualisation_status", "label"], ""),
                };
            });

            return {
                ...state,
                loading: false,
                mutualisations: mutualisationsArray,
                count: count,
                createdStatusId,
            };
        }
        case types.INSERT_MUTUALISATION_SUCCESS: {
            const mutualisations = [...state.mutualisations];
            const insertedIds = get(action, "insertedIds", []);
            const insertedData = get(action, "data", []);
            const newMutualisations = get(action, "mutualisations", {});

            newMutualisations.forEach((mut, index) => {
                set(mut, "key", get(insertedIds, [index, "id"], -1));
                set(mut, "status", "Created");
                set(mut, "supplier", get(insertedData, "supplier", ""));
                const delivery_date = DateTime.fromISO(
                    get(mut, ["delivery_date"], "")
                )
                    .setZone("Europe/Paris")
                    .toFormat("dd/LL/yyyy");

                const end_date = DateTime.fromISO(get(mut, ["end_date"], ""))
                    .setZone("Europe/Paris")
                    .toFormat("dd/LL/yyyy HH:mm");
                set(mut, "delivery_date", delivery_date);
                set(mut, "end_date", end_date);
                set(mut, "address", get(insertedData, ["full_address"]));
                mutualisations.push(mut);
            });

            return {
                ...state,
                loading: false,
                mutualisations: mutualisations,
                count: action.count + 4,
            };
        }
        case types.DELETE_MUTUALISATION_SUCCESS: {
            const mutualisations = [...state.mutualisations];
            const idRemovedMutualisation = get(action, "mutualisationId", -1);
            const indexRemoved = mutualisations.findIndex(
                (mut) => mut.key === idRemovedMutualisation
            );
            if (idRemovedMutualisation && indexRemoved !== -1)
                mutualisations.splice(indexRemoved, 1);
            return {
                ...state,
                mutualisations: mutualisations,
                loading: false,
            };
        }
        case "":
        default:
            return state;
    }
}

export default MutualisationsReducer;
