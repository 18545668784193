import { get, set } from "lodash";
import { types } from "./actions";

const initialState = {
    restaurants: {},
    loading: false,
    count: 0
};

function restaurantsReducer(state, action) {
    if (typeof state === "undefined") return initialState;

    const type = get(action, "type", "");
    switch (type) {
        case types.GET_RESTAURANTS_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case types.VALIDATE_PAYMENT_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case types.VALIDATE_PAYMENT_SUCCESS: {
                const restaurants = [...state.restaurants];
                const restaurantKey = get(action, "key", "");
                const restaurantIdx = restaurants.findIndex
                    ((item) => item?.id === restaurantKey);
                set(restaurants, [restaurantIdx, "manage_roles", 0,
                    "user", "payment", "account_payment_status"], "verified");

                return {
                    ...state,
                    loading: false,
                    restaurants: restaurants,
                }
            }
        case types.VALIDATE_PAYMENT_FAILURE:
            return {
                ...state,
                loading: false,
            }
        case types.SET_STATUS_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case types.SET_STATUS_SUCCESS: {
            const restaurants = [...state.restaurants];
            const key = get(action, "key", "");
            const restaurantIdx = restaurants.findIndex((item) => item?.id === key);
            const status = get(action, "status", false);
            set(restaurants, [restaurantIdx, "manage_roles", 0,
                "user", "validated"], status);

            return {
                ...state,
                loading: false,
                restaurants: restaurants,
            }
        }
        case types.SET_STATUS_FAILURE: {

            const restaurants = [...state.restaurants];
            const key = get(action, "key", "");
            const restaurantIdx = restaurants.findIndex
                ((item) => item?.id === key);
            const status = get(action, "status", false);
            set(restaurants, [restaurantIdx, "manage_roles",
                0, "user", "validated"], !status);


            return {
                ...state,
                loading: false,
                restaurants: restaurants,
            }
        }
        case types.GET_RESTAURANTS_SUCCESS:
            const restaurants = get(action, "restaurants", {});
            const count = get(action, "count", {});
            return {
                ...state,
                restaurants,
                loading: false,
                count: count,
            };
        case types.GET_RESTAURANTS_FAILURE:
            return {
                ...state,
                restaurants : [],
                loading: false,
                count: 0,
            }
        case "":
        default:
            return state;
    }
}

export default restaurantsReducer;
