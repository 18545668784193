import { createAction } from "utils/redux";

// Types
export const types = {
    LOGIN_REQUEST: "auth_LOGIN_REQUEST",
    LOGIN_SUCCESS: "auth_LOGIN_SUCCESS",
    GET_CURRENT_ON_LOGIN_REQUEST: "user_GET_CURRENT_ON_LOGIN_REQUEST",

    LOG_OUT_REQUEST: "auth_LOG_OUT_REQUEST",
    ADMIN_SUPERVISE: "auth_ADMIN_SUPERVISE_REQUEST",
};

// Actions
export default {
    loginRequest: (username = '', password = '') => createAction(types.LOGIN_REQUEST, { username, password }),
    loginSuccess: data => createAction(types.LOGIN_SUCCESS, { data }),
    getCurrentLoginRequest: (redirectToHome = false, userType = "") =>
        createAction(types.GET_CURRENT_ON_LOGIN_REQUEST, {
            redirectToHome,
            userType
        }),

    logOut: () => createAction(types.LOG_OUT_REQUEST),
    adminSuperviseRequest: (idUserToSupervise, history) =>
    createAction(types.ADMIN_SUPERVISE, { idUserToSupervise, history }),
}