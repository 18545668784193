const queries =  {
  getSpecs: `
    query getSpecs($filter: specs_bool_exp) {
      specs(where: $filter) {
        id,
        label,
        products_specs_aggregate(where: {product: {activated: {_eq: true}} }) {
          aggregate {
            count
          }
        }
      },
    },
  `,
}

export default queries;