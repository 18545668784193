const mutations = {
    deleteMutualisation: `
  mutation deleteMutualisation(
    $id: Int!
  ) {
    delete_mutualisations(where: {id: {_eq: $id}}) {
      returning {
        id
      }
    }
  }
`,
    insertMutualisations: `
  mutation insertMutualisations(
    $data: [mutualisations_insert_input!]!
  ) {
    insert_mutualisations(objects: $data) {
      returning {
          id
      }
    }
  }
`,
    insertAddress: `
  mutation insertAddress(
    $data: addresses_insert_input!
  ) {
    insert_addresses(objects: [$data]) {
      returning {
        id
      }
    }
  }
`,
};

export default mutations;
