import { authenticatedQuery } from './middlewares';
import { categoryQueries } from './graphQLRequests';
import { categoryMutations } from './graphQLRequests';

const categories = {
    getCategories: () => authenticatedQuery(categoryQueries.getCategories),

    insertSubCategory: (subCategory) => authenticatedQuery(categoryMutations.insertSubCategory, {}, {subCategory}),

    deleteAffectedProducts: (id) => authenticatedQuery(categoryMutations.deleteAffectedProducts, {}, { id }),
    deleteSubCategory: (id) => authenticatedQuery(categoryMutations.deleteSubCategory, {}, { id }),
}

export default categories;