import {fr} from './strings_fr';
import {en} from './strings_en';

const translations = {fr, en};

/**
 * Search for obj at a given path in state obj
 * @param {string} path
 * @param {object} obj
 * @returns {object}
 */
const pathResolver = (path, obj) => {
  if (path)
    return path.split('.').reduce((prev, curr) => {
      return prev ? prev[curr] : null;
    }, obj);
};

export let currentLocale = localStorage.getItem('SMA_CURRENT_LOCALE') || 'fr';

export const updateLocale = langage => {
  if (Object.keys(translations).indexOf(langage) !== -1) {
    localStorage.setItem('SMA_CURRENT_LOCALE', langage);
    currentLocale = langage;
  } else {
    throw new Error(`Invalid locale value: ${langage}`);
  }
};

export const t = key =>
  pathResolver(key, translations[currentLocale]) || pathResolver(key, translations['fr']) || null;
