import { useEffect, useState } from "react";
import {
    MutualisationsActions,
    MutualisationsSelectors,
} from "stores/mutualisations";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "antd";
import { DataTable } from "ui/components/table";
import { Loader } from "ui/components/loader";
import { t } from "assets";
import { SearchBar } from "components/SearchBar";
import "./style.scss";
import { ManageModal } from "./ManageModal";
import { DeleteModal } from "./DeleteModal";

export const Mutualisation = () => {
    const columns = [
        {
            title: t("mutualisationTable.columns.id"),
            key: t("mutualisationTable.columns.id"),
            dataIndex: "key",
            sorter: true
        },
        {
            title: t("mutualisationTable.columns.endMutualisation"),
            key: t("mutualisationTable.columns.endMutualisation"),
            dataIndex: "end_date",
            sorter: true
        },
        {
            title: t("mutualisationTable.columns.deliveryDelay"),
            key: t("mutualisationTable.columns.deliveryDelay"),
            dataIndex: "delivery_date",
            sorter: true
        },
        {
            title: t("mutualisationTable.columns.address"),
            key: t("mutualisationTable.columns.address"),
            dataIndex: "address",
        },
        {
            title: t("mutualisationTable.columns.threshold"),
            key: t("mutualisationTable.columns.threshold"),
            dataIndex: "threshold",
        },
        {
            title: t("mutualisationTable.columns.supplierName"),
            key: t("mutualisationTable.columns.supplierName"),
            dataIndex: "supplier",
            sorter: true
        },
        {
            title: t("mutualisationTable.columns.dispatchFees"),
            key: t("mutualisationTable.columns.dispatchFees"),
            dataIndex: "dispatch_fees",
        },
        {
            title: t("mutualisationTable.columns.status"),
            key: t("mutualisationTable.columns.status"),
            dataIndex: "status",
        },
        {
            title: t("mutualisationTable.columns.delete"),
            render: (_, value) => (
                <Button onClick={() => selectProduct(value)}>
                    {t("mutualisationTable.columns.delete")}
                </Button>
            ),
        },
    ];

    const dispatch = useDispatch();

    const [searchValue, onSearchChange] = useState("");
    const [isManageModal, setManageModal] = useState(false);
    const [isDeleteModal, setDeleteModal] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(10);
    const [sort, setSort] = useState(null);

    const mutualisations = useSelector(MutualisationsSelectors.mutualisations);
    const count = useSelector(MutualisationsSelectors.count);
    const loading = useSelector(MutualisationsSelectors.loading);

    const selectProduct = (product) => {
        setSelectedProduct(product);
        setDeleteModal(true);
    };

    useEffect(() => {
        dispatch(
            MutualisationsActions.getMutualisationsRequest(
                offset,
                limit,
                searchValue,
                sort
            )
        );
    }, [offset, limit, searchValue, sort, dispatch]);

    const loader = {
        spinning: loading,
        indicator: <Loader />,
    };

    const handleTableChange = (pagination, filters, sorter) => {
        if (sort?.key !== sorter?.column?.dataIndex || sort?.order !== sorter?.order) {
            setSort({
                key: sorter?.column?.dataIndex,
                order: sorter?.order
            })
        }
        setOffset((pagination.current - 1) * pagination.pageSize);
        setLimit(pagination.pageSize);
    };

    return (
        <div>
            <div>
                <SearchBar
                    onChange={onSearchChange}
                    placeholder={t("mutualisationTable.search")}
                />
                <div className="mutualisations-actions-container">
                    <Button onClick={() => setManageModal(true)}>
                        {t("mutualisationTable.new")}
                    </Button>
                </div>
            </div>
            <DataTable
                columns={columns}
                dataSource={mutualisations}
                loading={loader}
                pagination={{
                    pageSizeOptions: ["5", "10", "25", "100"],
                    showSizeChanger: true,
                    total: count,
                }}
               onChange={handleTableChange}
                rowKey={(record) => record.key}
            />
            <DeleteModal
                selectedProduct={selectedProduct}
                isModalOpen={isDeleteModal}
                handleClose={() => setDeleteModal(false)}
            />

            <ManageModal
                isModalOpen={isManageModal}
                handleClose={() => setManageModal(false)}
            />
        </div>
    );
};
