const queries =  {
  getTags: `
    query getTags {
      product_tags {
        id,
        label,
        color,
        tooltip
      },
    },
  `,
}

export default queries;