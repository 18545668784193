import { t } from "assets";
import { createSelector } from "reselect";
import { get } from "lodash";
const STORE_NAME = "restaurants";

const restaurants = createSelector(
    state => get(state, [STORE_NAME, "restaurants"], []),
    restaurants => {
        if (!restaurants || restaurants.constructor !== Array) {
            return []
        }

        return restaurants.map(restaurant => {
            const user = get(restaurant, ["manage_roles", 0, "user"], "");
            const name = get(restaurant, ["name"], "");
            const email = get(user, ["email"], "");
            const phone_number = get(restaurant, ["phone_number"], "");
            const payment_status =
                get(user, ["payment", "account_payment_status"], "");
            const deleted = user.deleted ? t("accountsTable.columns.deleted")
                : t("accountsTable.columns.active");
            const address = get(restaurant, "address", []);
            const payment = get(user, "payment", []);

            return ({
                key: restaurant.id,
                user: name !== null ? name : '',
                details: "",
                id: user.id,
                email: email !== null ? email : '',
                phone: phone_number !== null ? phone_number : '',
                payment: payment_status !== null ? payment_status : '',
                state: user.validated ? true : false,
                deleted: deleted,
                supervise: null,
                additional_data: {
                    firstname: get(user, "firstname", ""),
                    lastname: get(user, "lastname", ""),

                    city: get(address, "city", ""),
                    address_one: get(address, "address1", ""),
                    address_two: get(address, "address2", ""),
                    zip_code: get(address, "zip_code", ""),

                    payment_id: get(payment, "id", ""),
                    wallet_id: get(payment, "wallet_id", ""),
                    mandate_id: get(payment, "mandate_id", ""),
                    mangopay_id: get(payment, "mangopay_id", ""),
                    iban: get(payment, "iban", ""),

                    accept_equivalent: get(restaurant, "accept_equivalent_products", ""),
                    raw: restaurant,
                },
            });
        });
    }
);

const count = createSelector(
    state => get(state, [STORE_NAME, "count"], []),
    count => {
        return count;
    }
);

const loading = createSelector(
    state => get(state, [STORE_NAME, "loading"], []),
    loading => {
        return loading;
    }
);

const restaurantsSelector = {
    restaurants,
    count,
    loading,
};

export default restaurantsSelector;