import { takeLatest, put, call } from "redux-saga/effects";
import { MappingServices } from "services"
import { default as MappingSuppliersActions, types } from "./actions";
import { showError, showSuccess } from "utils/helpers/notifications";
import { get } from "lodash";

const MUTATION_TYPES = {
    UPDATE: 'UPDATE',
    INSERT: 'INSERT',
}

//exported sagas
function* getMapping() {
    yield takeLatest(types.GET_MAPPING_SUPPLIERS_REQUEST, getMappingSaga);
}

function* getMappingCount() {
    yield takeLatest(types.GET_MAPPING_COUNT_REQUEST, getMappingCountSaga);
}

function* upsertMapping() {
    yield takeLatest(types.UPSERT_SUPPLIER_MAPPING_REQUEST, upsertMappingSaga);
}

function* deleteMapping() {
    yield takeLatest(types.DELETE_SUPPLIER_MAPPING_REQUEST, deleteMappingSaga);
}

function* getMappingCountSaga(action) {
    const [error, response] = yield call(MappingServices.getMappingCount);
    try {
        if (!error && response) {
            const count =
                get(response, "mapping_catalog", null);
            yield put(
                MappingSuppliersActions.getMappingCountSuccess(
                    count
                )
            );
        }
        else
            throw new Error(`Invalid response from GraphQL: ${error}`)
    } catch (error) {
        yield put(MappingSuppliersActions.getMappingSuppliersFailure());
        showError(`mappingSuppliersTable.form.errors.getMappingSupplierSaga`);
    }
}

function* getMappingSaga(action) {

    let filter = {};
    if (action?.supplier?.supplier_id) {
        const suppId = action?.supplier?.supplier_id
        const searchValue = action?.searchValue?.length;
        filter = {
            supplier_information: {
                id: {_eq: suppId}
            }
        };
        if (searchValue) {
            filter["_or"] = [
                {external_ref: {_ilike: `%${action.searchValue}%`}},
                {sub_category: {label: {_ilike: `%${action.searchValue}%`}}},
                {origin: {_ilike: `%${action.searchValue}%`}}
            ];
        }
    }
    const { offset, limit } = action;
    const [error, response] = yield call(
        MappingServices.getMappingSuppliers,
        filter,
        offset,
        limit
    );

    try {
        if (!error && response) {
            const mappingSupp =
                get(response, "mapping_catalog", null);
            const count =
                get(response, "mapping_catalog_aggregate.aggregate.count", null);
            yield put(
                MappingSuppliersActions.getMappingSuppliersSuccess(
                    mappingSupp,
                    count
                )
            );
        }
        else
            throw new Error(`Invalid response from GraphQL: ${error}`)
    } catch (error) {
        yield put(MappingSuppliersActions.getMappingSuppliersFailure());
        showError(`mappingSuppliersTable.form.errors.getMappingSupplierSaga`);
    }
}

function adaptMappingForReducer(mapping, data, response) {
    let details = data?.newRef ?
    response?.insert_mapping_catalog_one : response?.update_mapping_catalog;

    mapping['category'] = data?.newRef ?
        get(details, ["sub_category", "category", "label"], "")
        : get(details, ["returning", 0, "sub_category", "category", "label"], "");
    
    mapping['sub_category'] = data?.newRef ?
        get(details, ["sub_category", "label"], "")
        : get(details, ["returning", 0, "sub_category", "label"], "");

    mapping['supplier_name'] = data?.newRef ?
        get(details, ["supplier_information", "name"], "")
        : get(details, ["returning", 0, "supplier_information", "name"], "");

    return mapping;
}

function* upsertMappingSaga(action) {
    const { data } = action;
    const supplierId = get(data, "supplierId", null);

    const mutationType = data?.newRef
        ? MUTATION_TYPES.INSERT :  MUTATION_TYPES.UPDATE;

    try {

        const idProduct = get(data, "id", null);
        const idSubCateg = get(data, "idSubCateg", null);
        const extRef = get(data, "extRef", null);
        const origin = get(data, "origin", "");

        if (!idSubCateg) throw new Error(`Missing category and/or subcategory`);
        let mapping = {
            id_supplier: supplierId,
            id_subcategory: idSubCateg,
            external_ref: data?.newRef ? data?.newRef : extRef,
            origin: origin
        }

        let error, response = null;

        switch (mutationType) {
            case MUTATION_TYPES.UPDATE: {
                [error, response] = yield call(
                    MappingServices.updateMappingSuppliers,
                    idProduct,
                    mapping
                )
                break;
            }
            case MUTATION_TYPES.INSERT: {
                [error, response] = yield call(
                    MappingServices.insertMappingSuppliers,
                    mapping
                )
                break;
            }
            default:
                throw new Error(`Invalid mutation type`);
        };

        if (!error && response) {
            adaptMappingForReducer(mapping, data, response);
            const upsertedMappingId =
                data?.newRef ?
                    get(response, ["insert_mapping_catalog_one", "id"], null) : idProduct;

            yield put(MappingSuppliersActions.upsertSupplierMappingSuccess
                (mapping, upsertedMappingId));
            showSuccess(`mappingSuppliersTable.form.success.upsertMappingSupplierSaga`);
            return;
        }
        throw new Error(`An error occured during ${mutationType} of mapping`)
    } catch (e) {
        showError(`mappingSuppliersTable.form.errors.upsertMappingSupplierSaga`);
    }
}

function* deleteMappingSaga(action) {
    const { smappingId } = action;

    try {
        const [error, response] = yield call
            (MappingServices.deleteMappingSuppliers, smappingId);
        if (!error && response) {
            yield put(
                MappingSuppliersActions.deleteSupplierMappingSuccess(smappingId)
            );
            showSuccess(`mappingSuppliersTable.form.success.deleteMappingSupplierSaga`);
            return;
        }
        throw new Error(`An error occured during deletion of mapping`)
    } catch (e) {
        showError(`mappingSuppliersTable.form.errors.deleteMappingSupplierSaga`);
    }
}


const sagas = [
    getMapping(),
    upsertMapping(),
    deleteMapping(),
    getMappingCount()
];

export default sagas;