import { takeLatest, put } from 'redux-saga/effects';
import { types } from './actions';
import { UserActions } from '../user';
import { AuthActions } from '../auth';

//exported sagas
function* resetAllReducers() {
  yield takeLatest(types.RESET_REDUCERS, resetAllReducersSaga);
};

//logical sagas
function* resetAllReducersSaga() {
  yield put(UserActions.resetReducers());
  yield put(AuthActions.resetReducers());
};

export default [
  resetAllReducers(),
];
