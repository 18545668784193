import { useEffect, useRef, useState } from "react";
import { SpecsActions, SpecsSelectors } from "stores/specs";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "antd";
import { DataTable } from "ui/components/table";
import { Loader } from "ui/components/loader";
import { t } from "assets";
import { UpsertModal, MODAL_PROPERTIES } from "./UpsertModal";
import { DeleteModal } from "./DeleteModal";
import { SearchBar } from "components/SearchBar";
import "./style.scss";

export const Specs = () => {

    const columns = [
        {
            title: t("specsTable.columns.id"),
            dataIndex: "key",
        },
        {
            title: t("specsTable.columns.name"),
            dataIndex: "name",
        },
        {
            title: t("specsTable.columns.count"),
            dataIndex: "count",
        },
        {
            title: t("specsTable.columns.edit"),
            render: (_, value) => (
                <Button
                    onClick={() => upsertModalRef.current.showModal(MODAL_PROPERTIES.EDIT, value)}
                    disabled={value?.count !== 0}
                >
                    {t("specsTable.columns.edit")}
                </Button>
            )
        },
        {
            title: t("specsTable.columns.delete"),
            render: (_, value) => (
                <Button
                    onClick={() => deleteModalRef.current.showModal(value)}
                    disabled={value?.count !== 0}
                >
                    {t("specsTable.columns.delete")}
                </Button>
            )
        },
    ];

    const dispatch = useDispatch();
    const specs = useSelector(SpecsSelectors.specs);
    const loading = useSelector(SpecsSelectors.loading);

    const [isModalOpen, setDisplayModal] = useState(false);
    const [isDeleteModalOpen, setDeleteModal] = useState(false);

    const [searchValue, onSearchChange] = useState("");

    useEffect(() => {
        dispatch(SpecsActions.getSpecsRequest(searchValue));
    }, [dispatch, searchValue]);

    const loader = {
        spinning: loading,
        indicator: <Loader />,
    }
    const upsertModalRef = useRef();
    const deleteModalRef = useRef();

    return (
        <div>
            <SearchBar onChange={onSearchChange} placeholder={t("specsTable.search")} />
            <div className="spec-actions-container">
                <Button
                    className="spec-action"
                    onClick={() => upsertModalRef.current.showModal(MODAL_PROPERTIES.NEW)}
                >
                    {t("specsTable.new")}
                </Button>
            </div>
            <DataTable columns={columns} dataSource={specs} loading={loader} />
            <UpsertModal 
                ref={upsertModalRef}
                isModalOpen={isModalOpen}
                setDisplayModal={setDisplayModal}
            />
            <DeleteModal
                ref={deleteModalRef} 
                isModalOpen={isDeleteModalOpen}
                setDeleteModal={setDeleteModal}
            />
        </div>
    );
}