import { createSelector } from "reselect";
import { get } from "lodash";
const STORE_NAME = "smapping";

const loading = createSelector(
    state => get(state, [STORE_NAME, "loading"], false),
    loading => loading
);

const smapping = createSelector(
    state => get(state, [STORE_NAME, "smapping"], false),
    smapping => smapping
);

const suppliers = createSelector(
    state => get(state, [STORE_NAME, "suppliers"], false),
    suppliers => suppliers
);

const count = createSelector(
    state => get(state, [STORE_NAME, "count"], false),
    count => count
);

const selectors =  {
    smapping,
    loading,
    suppliers,
    count,
};

export default selectors;