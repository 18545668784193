const mutations = {
  insertSubCategory: `
    mutation insertOneSubCategory(
      $subCategory: sub_categories_insert_input!
    ) {
      insert_sub_categories_one(object: $subCategory) {
        id
      }
    }
  `,
  // delete (disabled) products linked to the subcategory to delete
  deleteAffectedProducts: `
    mutation deleteAffectedProducts(
      $id: Int!
    ) {
      delete_products_subcategories(where: {_and: [{id_subcategory: {_eq: $id}}, {product: {activated: {_eq: false}}}]} ) {
        returning {
          id
        }
      }
    }
  `,
  deleteSubCategory: `
    mutation deleteSubCategory(
      $id: Int!
    ) {
      delete_sub_categories(where: {id: {_eq: $id}}) {
        returning {
          id
        }
      }
    }
`};

export default mutations;