import { createAction } from "utils/redux";

// Types
export const types = {
    GET_RESTAURANTS_REQUEST: "restaurants_GET_RESTAURANTS_REQUEST",
    GET_RESTAURANTS_SUCCESS: "restaurants_GET_RESTAURANTS_SUCCESS",
    GET_RESTAURANTS_FAILURE: "restaurants_GET_RESTAURANTS_FAILURE",

    VALIDATE_PAYMENT_REQUEST: "restaurants_VALIDATE_PAYMENT_REQUEST",
    VALIDATE_PAYMENT_SUCCESS: "restaurants_VALIDATE_PAYMENT_SUCCESS",
    VALIDATE_PAYMENT_FAILURE: "restaurants_VALIDATE_PAYMENT_FAILURE",

    SET_STATUS_REQUEST: "restaurants_SET_STATUS_REQUEST",
    SET_STATUS_SUCCESS: "restaurants_SET_STATUS_SUCCESS",
    SET_STATUS_FAILURE: "restaurants_SET_STATUS_FAILURE",
};

// Actions
export default {
    getRestaurantsRequest: (offset, limit, searchValue) =>
        createAction(types.GET_RESTAURANTS_REQUEST, { offset, limit, searchValue }),
    getRestaurantsSuccess: (restaurants, count) =>
        createAction(types.GET_RESTAURANTS_SUCCESS, { restaurants, count }),
    getRestaurantsFailure: () =>
        createAction(types.GET_RESTAURANTS_FAILURE),

    validatePaymentRequest: (id, key) =>
        createAction(types.VALIDATE_PAYMENT_REQUEST, { id, key }),
    validatePaymentSuccess: (key) =>
        createAction(types.VALIDATE_PAYMENT_SUCCESS, { key }),
    validatePaymentFailure: () =>
        createAction(types.VALIDATE_PAYMENT_FAILURE),

    setStatusRequest: (userId, key, status) =>
        createAction(types.SET_STATUS_REQUEST, { userId, key, status }),
    setStatusSuccess: (key, status) =>
        createAction(types.SET_STATUS_SUCCESS, { key, status }),
    setStatusFailure: (key, status) =>
        createAction(types.SET_STATUS_FAILURE, { key, status }),
}