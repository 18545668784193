import { createAction } from "utils/redux";

// Types
export const types = {
    GET_MAPPING_SUPPLIERS_REQUEST: "smapping_GET_MAPPING_SUPPLIERS_REQUEST",
    GET_MAPPING_COUNT_REQUEST: "smapping_GET_MAPPING_COUNT_REQUEST",
    GET_MAPPING_COUNT_SUCCESS: "smapping_GET_MAPPING_COUNT_SUCCESS",
    GET_MAPPING_SUPPLIERS_SUCCESS: "smapping_GET_MAPPING_SUPPLIERS_SUCCESS",
    GET_MAPPING_SUPPLIERS_FAILURE: "smapping_GET_MAPPING_SUPPLIERS_FAILURE",

    UPSERT_SUPPLIER_MAPPING_REQUEST: "smapping_UPSERT_SUPPLIER_MAPPING_REQUEST",
    UPSERT_SUPPLIER_MAPPING_SUCCESS: "smapping_UPSERT_SUPPLIER_MAPPING_SUCCESS",
    UPSERT_SUPPLIER_MAPPING_FAILURE: "smapping_UPSERT_SUPPLIER_MAPPING_FAILURE",

    DELETE_SUPPLIER_MAPPING_REQUEST: "smapping_DELETE_SUPPLIER_MAPPING_REQUEST",
    DELETE_SUPPLIER_MAPPING_SUCCESS: "smapping_DELETE_SUPPLIER_MAPPING_SUCCESS",
    DELETE_SUPPLIER_MAPPING_FAILURE: "smapping_DELETE_SUPPLIER_MAPPING_FAILURE",

};

// Actions
const getMappingSuppliersRequest = (offset, limit, searchValue, supplier) =>
    createAction(types.GET_MAPPING_SUPPLIERS_REQUEST,{ offset, limit, searchValue, supplier });
const getMappingSuppliersSuccess = (smapping, count) =>
    createAction(types.GET_MAPPING_SUPPLIERS_SUCCESS, { smapping, count });
const getMappingSuppliersFailure = () =>
    createAction(types.GET_MAPPING_SUPPLIERS_FAILURE);

const getMappingCountRequest = () =>
    createAction(types.GET_MAPPING_COUNT_REQUEST);
const getMappingCountSuccess = (count) =>
    createAction(types.GET_MAPPING_COUNT_SUCCESS, { count });

    const upsertSupplierMappingRequest = (data) =>
    createAction(types.UPSERT_SUPPLIER_MAPPING_REQUEST, { data });
const upsertSupplierMappingSuccess = (smapping, smappingId) =>
    createAction(types.UPSERT_SUPPLIER_MAPPING_SUCCESS, { smapping, smappingId });
const upsertSupplierMappingFailure = () =>
    createAction(types.UPSERT_SUPPLIER_MAPPING_FAILURE)

const deleteSupplierMappingRequest = (smappingId) =>
    createAction(types.DELETE_SUPPLIER_MAPPING_REQUEST, { smappingId });
const deleteSupplierMappingSuccess = (smappingId) =>
    createAction(types.DELETE_SUPPLIER_MAPPING_SUCCESS, { smappingId });
const deleteSupplierMappingFailure = () =>
    createAction(types.DELETE_SUPPLIER_MAPPING_FAILURE);

const actions = {
    getMappingSuppliersRequest,
    getMappingSuppliersSuccess,
    getMappingSuppliersFailure,

    getMappingCountRequest,
    getMappingCountSuccess,

    upsertSupplierMappingRequest,
    upsertSupplierMappingSuccess,
    upsertSupplierMappingFailure,

    deleteSupplierMappingRequest,
    deleteSupplierMappingSuccess,
    deleteSupplierMappingFailure
}

export default actions;