import { authenticatedQuery } from './middlewares';
import { mappingSuppliersQueries } from './graphQLRequests';
import { mappingSuppliersMutations } from './graphQLRequests';

const mapping = {
    getMappingCount: () => authenticatedQuery(mappingSuppliersQueries.getMappingCount, {}, {}),
    getMappingSuppliers: (filter, offset, limit) => authenticatedQuery(mappingSuppliersQueries.getMappingSuppliers, {}, {filter, offset, limit}),
    updateMappingSuppliers: (id, data) => authenticatedQuery(mappingSuppliersMutations.updateMapping, {}, {id, data}),
    insertMappingSuppliers: (data) => authenticatedQuery(mappingSuppliersMutations.insertMapping, {}, {data}),
    deleteMappingSuppliers: (id) => authenticatedQuery(mappingSuppliersMutations.deleteMapping, {}, {id}),
}

export default mapping;