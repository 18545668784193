import { createReducer } from "reduxsauce";
import { types } from "./actions";
import { get } from "lodash";

const initialState = {
    token: "",
    attempts: 0
};

const loginSuccess = (state = initialState, action) => {
    const {
        token,
        refresh_token,
        token_type,
        authorization,
        username,
    } = get(action, "data", {});

    return {
        ...state,
        token,
        refresh_token,
        token_type,
        authorization,
        attempts: 0,
        email: username,
        username: username
    };
};


export default createReducer(initialState, {
    [types.LOGIN_SUCCESS]: loginSuccess
});