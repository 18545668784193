import { takeLatest, call, put } from "redux-saga/effects";
import { default as RestaurantsActions, types } from "./actions";
import { RestaurantServices } from "services";
import { showError, showSuccess } from "utils/helpers/notifications"
import { get } from "lodash";

//exported sagas
function* getRestaurants() {
    yield takeLatest(types.GET_RESTAURANTS_REQUEST, getRestaurantsSaga);
}

//logical sagas

function* getRestaurantsSaga(action) {
    let filter = {manage_roles: {user: {deleted: {_eq: false}}}};
    if (action.searchValue.length > 0) {
        filter = {
            manage_roles: {user: {deleted: {_eq: false}}},
            name: {_ilike: `%${action.searchValue}%`}
        };
    }
    const [error, response] =
        yield call(RestaurantServices.getRestaurants, action.offset, action.limit, filter);

    if (!error && response) {
        const restaurants = get(response, ["restaurants"], []);
        const count = get(response, ["restaurants_aggregate", "aggregate", "count"], 0); 
        yield put(RestaurantsActions.getRestaurantsSuccess(restaurants, count));
    }
    else {
        yield put(RestaurantsActions.getRestaurantsFailure());
        showError('accountsTable.errors.fetchingData');
    }
}

function* setStatusRequest() {
    yield takeLatest(types.SET_STATUS_REQUEST, setStatusSaga);
}

function* setStatusSaga(action) {
    const [error, response] = yield call(RestaurantServices.setStatus, action.userId, action.status);
    if (!error && response) {
        yield put(RestaurantsActions.setStatusSuccess(action.key, action.status));
        showSuccess('accountsTable.success.setStatus');
    }
    else {
        yield put(RestaurantsActions.setStatusFailure(action.key, action.status));
        showError('accountsTable.errors.setStatus');
    }
}

function* validatePayment() {
    yield takeLatest(types.VALIDATE_PAYMENT_REQUEST, validatePaymentSaga);
}

function* validatePaymentSaga(action) {
    const [error, response] = yield call(RestaurantServices.validatePayment, action.id);
    if (!error && response) {
        yield put(RestaurantsActions.validatePaymentSuccess(action.key));
        showSuccess('accountsTable.success.validatePayment');
    }
    else {
        yield put(RestaurantsActions.validatePaymentFailure());
        showError('accountsTable.errors.validatePayment');
    }
}

const sagas = [
    getRestaurants(),
    validatePayment(),
    setStatusRequest()
];

export default sagas;
