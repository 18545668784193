const setStatus = `
    mutation updateUsers(
        $id: Int!,
        $status: Boolean!
    ) {
        update_users(where: {id: {_eq: $id}}, _set: {validated: $status}) {
            returning {
                id
            }
        }
    }
    `;

const validatePayment = `
    mutation updatePayment(
        $id: Int!,
    ) {
        update_payments(
            where: {id: {_eq: $id}},
            _set: {account_payment_status: "verified", active: true}
            ) {
            returning {
                id
            }
        }
    }
    `;


export default {
    setStatus,
    validatePayment,
};