import { authenticatedQuery } from './middlewares';
import { supplierQueries } from './graphQLRequests';
import { supplierMutations } from './graphQLRequests';
const EXPORT_PRODUCTS_ROUTE = process.env.REACT_APP_EXPORT_PRODUCTS;

const getSuppliers = (offset, limit, filter) => authenticatedQuery(supplierQueries.getSuppliers, {}, { offset, limit, filter });
const getSuppliersNames = () => authenticatedQuery(supplierQueries.getSuppliersNames);
const validatePayment = (id) => authenticatedQuery(supplierMutations.validatePayment, {}, { id });
const setStatus = (id, status) => authenticatedQuery(supplierMutations.setStatus, {}, { id, status });
const exportProducts = async (token, supplierId) => {
    const response = await fetch(`${EXPORT_PRODUCTS_ROUTE}/${supplierId}`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
    });

    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }

    return response.json();
};

export default {
    getSuppliers,
    getSuppliersNames,
    validatePayment,
    setStatus,
    exportProducts,
}