import { get, set } from "lodash";
import { types } from "./actions";

const initialState = {
    categories: [],
    loading: false
};

function categoriesReducer(state, action) {
    if (typeof state === "undefined") return initialState;

    const type = get(action, "type", "");
    switch (type) {
        case types.GET_CATEGORIES_REQUEST:
        case types.ADD_SUB_CATEGORY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.GET_CATEGORIES_SUCCESS: {
            const categories = get(action, "categories", {});
            return {
                ...state,
                loading: false,
                categories: categories,
            };
        };
        case types.GET_CATEGORIES_FAILURE:
            return {
                ...state,
                categories : [],
                loading: false,
            };

        case types.ADD_SUB_CATEGORY_SUCCESS: {
            const categories = [...state.categories] 
            const { idInserted, subCategory } = action;
            const categoryAffectedIdx = categories.findIndex
            ((item) => item?.id === subCategory?.id_category);

            // if the parent category affected exists, we add the new subcategory into it
            if (categoryAffectedIdx !== -1 && categories[categoryAffectedIdx]) {
                const subCategories = categories[categoryAffectedIdx]?.sub_categories;
                subCategories.push({ ...subCategory, id: idInserted, label: subCategory.label });
                set(categories, [categoryAffectedIdx, "sub_categories"], subCategories);
            }
            return {
                ...state,
                loading: false,
                categories: categories
            };
        }
        case types.ADD_SUB_CATEGORY_FAILURE:
            return {
                ...state,
                loading: false,
            };

        case types.DELETE_SUB_CATEGORY_SUCCESS: {
            const { idDeleted, idParent } = action;
            const categories = [...state.categories];
            // getting the index of the parent category affected
            const categoryAffectedIdx = categories.findIndex
            ((item) => item?.id === idParent);

            if (categoryAffectedIdx !== -1 && categories[categoryAffectedIdx]) {
                const sub = get(categories, [categoryAffectedIdx, "sub_categories"], []);
                // finding and removing the sub category from the parent category
                const subCategoryRemovedIdx = sub
                    .findIndex((sub_cat => sub_cat?.id === idDeleted));
                if (subCategoryRemovedIdx !== -1)
                    categories[categoryAffectedIdx]?.sub_categories.splice(subCategoryRemovedIdx, 1);
            }
            return {
                ...state,
                loading: false,
                categories: categories
            };
        }
        case types.DELETE_SUB_CATEGORY_FAILURE:
        case types.DELETE_SUB_CATEGORY_REQUEST: {
            return {
                ...state,
                loading: false,
            };
        }
        case "":
        default:
            return state;
    }
}

export default categoriesReducer;
