const queries =  {
  getCategories: `
  query getCategories {
    categories {
      id,
      label,
      color,
      order,
      sub_categories {
        id,
        label,
        products_subcategories_aggregate(where: {product: {activated: {_eq: true}} }) {
          aggregate {
            count
          }
        }
      }
    },
  },
  `,
}

export default queries;