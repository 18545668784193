const mutations = {
  insertTag: `
    mutation insertOneTag(
      $data: product_tags_insert_input!
    ) {
      insert_product_tags_one(object: $data) {
        id
      }
    }
  `,
  updateTag: `
    mutation updateTag(
      $id: Int!,
      $data: product_tags_set_input!
    ) {
      update_product_tags(where: {id: {_eq: $id}}, _set: $data) {
        returning {
          id
        }
      }
    }
  `,
  deleteTag: `
    mutation deleteTag(
      $id: Int!
    ) {
      delete_product_tags(where: {id: {_eq: $id}}) {
        returning {
          id
        }
      }
    }
`};

export default mutations;