import { takeLatest, put, call } from "redux-saga/effects";
import { MutualisationServices } from "services";
import { default as MutualisationsSuppliersActions, types } from "./actions";
import { select } from "redux-saga/effects";
import { showError, showSuccess } from "utils/helpers/notifications";
import { get } from "lodash";
const { DateTime } = require("luxon");

//exported sagas
function* getMutualisations() {
    yield takeLatest(types.GET_MUTUALISATIONS_REQUEST, getMutualisationsSaga);
}

function* insertMutualisation() {
    yield takeLatest(
        types.INSERT_MUTUALISATION_REQUEST,
        insertMutualisationSaga
    );
}

function* deleteMutualisations() {
    yield takeLatest(
        types.DELETE_MUTUALISATIONS_REQUEST,
        deleteMutualisationsSaga
    );
}

function* getMutualisationsSaga(action) {
    let filter = {};
    let order = {};
    const searchValue = action?.searchValue?.length;
    const sort = action?.sort;
    if (searchValue) {
        filter["_or"] = [
            {
                supplier_information: {
                    name: { _ilike: `%${action.searchValue}%` },
                },
            },
            {
                mutualisation_status: {
                    label: { _ilike: `%${action.searchValue}%` },
                },
            },
        ];
    }
    if (sort) {
        switch (sort.key) {
            case "end_date":
            case "delivery_date": {
                order = [{ [sort.key]: sort.order === "ascend" ? "asc" : "desc" }];
                break;
            }
            case "key": {
                order = [{ "id": sort.order === "ascend" ? "asc" : "desc" }];
                break;
            }
            case "supplier": {
                order = [{ supplier_information: {name: sort.order === "ascend" ? "asc" : "desc"}}];
                break;
            }
            default:
                break;
        }
    }

    try {
        const { offset, limit } = action;
        const [error, response] = yield call(
            MutualisationServices.getMutualisations,
            filter,
            offset,
            limit,
            order
        );

        if (!error && response) {
            const mutualisations = get(response, "mutualisations", null);
            const count = get(
                response,
                "mutualisations_aggregate.aggregate.count",
                null
            );
            const createdStatusId = get(
                response,
                ["mutualisation_status", 0, "id"],
                -1
            );

            yield put(
                MutualisationsSuppliersActions.getMutualisationsSuccess(
                    mutualisations,
                    count,
                    createdStatusId
                )
            );
        } else throw new Error(`Invalid response from GraphQL: ${error}`);
    } catch (error) {
        showError(`mutualisationTable.form.errors.fetchingData`);
    }
}

function* insertMutualisationSaga(action) {
    const { data } = action;

    try {
        const createdStatusId = yield select(
            (state) => state.mutualisations.createdStatusId
        );
        if (!createdStatusId || createdStatusId < 0)
            throw new Error(`Invalid id`);

        // get id address or create it
        let filterAddress = {};
        filterAddress["_and"] = [
            {
                address1: { _eq: get(data, "address1", null) },
                address2: { _eq: get(data, "address2", null) },
                city: { _eq: get(data, "city", null) },
                zip_code: { _eq: get(data, "zip_code", null) },
            },
        ];

        let [error, response] = yield call(
            MutualisationServices.getAddress,
            filterAddress
        );
        let idAddress = get(response, ["addresses", 0, "id"], null);
        if (!idAddress) {
            [error, response] = yield call(
                MutualisationServices.insertAddress,
                {
                    address1: get(data, "address1", null),
                    address2: get(data, "address2", null),
                    city: get(data, "city", null),
                    zip_code: get(data, "zip_code", null),
                }
            );
            idAddress = get(
                response,
                ["insert_addresses", "returning", 0, "id"],
                null
            );
        }
        if (!idAddress) throw new Error(`Invalid address id`);

        // create mutualisations
        let end_date = DateTime.fromISO(get(data, ["end_date"], null));
        end_date = end_date.set({ hour: 23, minute: 59, second: 59 });
        let mutualisationList = [];
        for (let i = 0; i < 4; i++) {
            const dDate = end_date.plus({ days: get(data, "delay", null) });
            mutualisationList.push({
                end_date: end_date.plus({ weeks: i }),
                delivery_date: dDate.plus({ weeks: i }),
                threshold: get(data, "threshold", null),
                id_supplier: get(data, "id_supplier", null),
                dispatch_fees: get(data, "dispatch_fees", null),
                id_status: createdStatusId,
                id_address: idAddress,
            });
        }

        [error, response] = yield call(
            MutualisationServices.insertMutualisations,
            mutualisationList
        );

        if (!error && response) {
            const insertedIds = get(
                response,
                ["insert_mutualisations", "returning"],
                null
            );
            yield put(
                MutualisationsSuppliersActions.insertMutualisationSuccess(
                    mutualisationList,
                    data,
                    insertedIds
                )
            );
            showSuccess(
                `mutualisationTable.form.success.insertMutualisationsSaga`
            );
            return;
        }
        throw new Error(`An error occured during creation of mutualisation`);
    } catch (e) {
        showError(`mutualisationTable.form.errors.insertMutualisationsSaga`);
    }
}

function* deleteMutualisationsSaga(action) {
    const { mutualisationId } = action;

    try {
        const [error, response] = yield call(
            MutualisationServices.deleteMutalisation,
            mutualisationId
        );
        if (!error && response) {
            yield put(
                MutualisationsSuppliersActions.deleteMutualisationSuccess(
                    mutualisationId
                )
            );
            showSuccess(
                `mutualisationTable.deleteModal.success.deleteMutualisationSaga`
            );
            return;
        }
        throw new Error(`An error occured during deletion of mutualisation`);
    } catch (e) {
        showError(
            `mutualisationTable.deleteModal.errors.deleteMutualisationSaga`
        );
    }
}

const sagas = [
    getMutualisations(),
    deleteMutualisations(),
    insertMutualisation(),
];

export default sagas;
