import { get } from "lodash";
import { types } from "./actions";

const initialState = {
    loading: false,
};

function facturationReducer(state, action) {
    if (typeof state === "undefined") return initialState;

    const type = get(action, "type", "");
    switch (type) {
        case types.GET_FACTURATION_SUCCESS :
            return initialState;
        case types.GET_FACTURATION_FAILURE:
            return initialState;
        case types.GET_FACTURATION_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case "":
        default:
            return state;
    }
}

export default facturationReducer;
