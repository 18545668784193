import { authenticatedQuery } from './middlewares';
import { tagQueries } from './graphQLRequests';
import { tagMutations } from './graphQLRequests';

const tags = {
    getTags: () => authenticatedQuery(tagQueries.getTags),
    insertTag: (data) => authenticatedQuery(tagMutations.insertTag, {}, {data}),
    updateTag: (id, data) => authenticatedQuery(tagMutations.updateTag, {}, {id, data}),
    deleteTag: (id) => authenticatedQuery(tagMutations.deleteTag, {}, { id }),
}

export default tags;