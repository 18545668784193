import { Modal, Button } from "antd";
import { t } from "assets";
import { get } from "lodash";

const DetailsModal = ({ isModalOpen, handleClose, validatePayment, additionalDataKey, suppliers }) => {
    const suppIdx = suppliers.findIndex((item) => item?.key === additionalDataKey);
    const data = get(suppliers, suppIdx, []);
    const additionalData = get(data, "additional_data", []);
    const jsondata = JSON.stringify(get(additionalData, "raw", []));

    const Siret = (additionalData) => {
        const s = get(additionalData, "siret", "");

        if (s && s.length > 0)
            return s;
        return t("accountsTable.detailsModal.undefined");
    }

    const DeliveryAreas = (additionalData) => {
        const areas = get(additionalData, "delivery_areas", []);
        if (areas.length === 0)
            return t("accountsTable.detailsModal.undefined");
        return (
            <div>
                {areas.map((dA, idx) =>
                <p style={{display: 'inline'}} key={idx}>
                    ({get(dA, ["delivery_area" , "label"], "")})
                </p>
            )}
            </div>
        )
    }

    const DeliveryDays = (additionalData) => {
        const days = get(additionalData, "delivery_days", []);
        if (days.length === 0)
            return t("accountsTable.detailsModal.undefined");
        return (
            <div>
                <ul>
                    {days.map((d, idx) =>
                    <li key={idx}>
                        {t(`accountsTable.detailsModal.${get(d, ["week_day", "label"], "")}`)}
                    </li>
                )}
                </ul>
            </div>
        )
    }

    const Thresholds = (additionalData) => {
        const thrs = get(additionalData, "thresholds", []);
        if (thrs.length === 0)
            return t("accountsTable.detailsModal.undefined");
        let sortedThresholds = [];
        thrs.forEach(threshold => {
            sortedThresholds.push(
                [get(threshold, ["delivery_threshold", "threshold"], 0),
                get(threshold, ["delivery_threshold", "cost"], 0)]
            )
        });
        sortedThresholds.sort(function(a, b) {
            return a[0] - b[0];
        });
        return (
            <ol>
                {sortedThresholds.map((sT, idx) =>
                <li key={idx}>
                    seuil: <strong>{sT[0]}€ </strong>
                    coût: <strong>{(sT[1] !== "" && sT[1]> 0 ? sT[1] : 0)}€</strong>
                </li>
            )}
            </ol>
        )
    }

    const DeliveryDelay = (additionalData) => {
        const delay = get(additionalData, "delivery_delay", "");
        if (delay === "" || delay === null)
            return t("accountsTable.detailsModal.undefined");
        return `${delay}j`;
    };

    const DeliveryDelayThreshold = (additionalData) => {
        const delay = get(additionalData, "delivery_delay_threshold", "");
        if (delay === "" || delay === null)
            return t("accountsTable.detailsModal.undefined");
        return `${delay}h00`;
    };

    const FirstDeliveryDelay = (additionalData) => {
        const delay = get(additionalData, "first_delivery_delay", "");
        if (delay === "" || delay === null)
            return t("accountsTable.detailsModal.undefined");
        return `${delay}j`;
    };

    const BooleanInfo = (boolean) => {
        if (boolean === true)
            return (t("accountsTable.detailsModal.true"));
        else if (boolean === false)
            return (t("accountsTable.detailsModal.false"));
        return t("accountsTable.detailsModal.undefined");
    }

    return (
        <Modal
        title={t("accountsTable.detailsModal.title")}
        open={isModalOpen}
        footer={null} 
        maskClosable={false}
        onCancel={() => handleClose(false)}
        bodyStyle={{ overflowY: 'auto', maxHeight: 'calc(100vh - 300px)'}}
        >
            <div>
                <div>
                    <h4>{t("accountsTable.detailsModal.siret")}</h4>
                    <p>{Siret(additionalData)}</p>
                </div>
                <div>
                    <h4>{t("accountsTable.detailsModal.deliveryZones")}</h4>
                    {DeliveryAreas(additionalData)}
                </div>
                <div>
                    <h4>{t("accountsTable.detailsModal.deliveryDays")}</h4>
                    {DeliveryDays(additionalData)}
                </div>
                <div>
                    <h4>{t("accountsTable.detailsModal.orderThresholds")}</h4>
                    {Thresholds(additionalData)}
                </div>
                <div>
                    <h4>{t("accountsTable.detailsModal.deliveryDelay")}</h4>
                    <p>{DeliveryDelay(additionalData)}</p>
                </div>
                <div>
                    <h4>{t("accountsTable.detailsModal.firstDeliveryDelay")}</h4>
                    <p>{FirstDeliveryDelay(additionalData)}</p>
                </div>
                <div>
                    <h4>{t("accountsTable.detailsModal.deliveryDelayThreshold")}</h4>
                    <p>{DeliveryDelayThreshold(additionalData)}</p>
                </div>
                <div>
                    <h4>{t("accountsTable.detailsModal.manualOrderConfirmation")}</h4>
                    <p>{BooleanInfo(
                        get(additionalData, "manual_order_confirmation"))}</p>
                </div>
                <div>
                    <h4>{t("accountsTable.detailsModal.user")}</h4>
                    <p>{t("accountsTable.detailsModal.id")}{" : "}
                        {get(data, "id", "")}</p>
                    <p>{t("accountsTable.detailsModal.email")}
                        {" : "}{get(data, "email", "")}</p>
                    <p>{t("accountsTable.detailsModal.deleted")}
                        {" : "}{get(data, "deleted", "")}</p>
                    <p>{t("accountsTable.detailsModal.firstname")}
                        {" : "}{get(additionalData, "firstname", "")}</p>
                    <p>{t("accountsTable.detailsModal.name")}
                        {" : "}{get(additionalData, "lastname", "")}</p>
                    <p>{t("accountsTable.detailsModal.phone")}
                        {" : "}{get(data, "phone", "")}</p>
                </div>
                <div>
                    <h4>{t("accountsTable.detailsModal.address")}</h4>
                    <p>{t("accountsTable.detailsModal.address1")}
                        {" : "}{get(additionalData, "address_one", "")}</p>
                    <p>{t("accountsTable.detailsModal.address2")}
                        {" : "}{get(additionalData, "address_two", "")}</p>
                    <p>{t("accountsTable.detailsModal.city")}
                        {" : "}{get(additionalData, "city", "")}</p>
                    <p>{t("accountsTable.detailsModal.zipCode")}
                        {" : "}{get(additionalData, "zip_code", "")}</p>
                </div>
                <div>
                    <h4>{t("accountsTable.detailsModal.payment")}</h4>
                    <Button
                        onClick={() => validatePayment(get(additionalData, ["payment_id"], ""))}
                        disabled={get(additionalData, "mangopay_id", "")?.length === 0}
                    >
                        {t("accountsTable.detailsModal.enablePayment")}
                    </Button>
                    <p>{t("accountsTable.detailsModal.accountPaymentStatus")}
                        {" : "}{get(data, "payment", "")}</p>
                    <p>{t("accountsTable.detailsModal.walletId")}
                        {" : "}{get(additionalData, "wallet_id", "")}</p>
                    <p>{t("accountsTable.detailsModal.mandateId")}
                        {" : "}{get(additionalData, "mandate_id", "")}</p>
                    <p>{t("accountsTable.detailsModal.mangopayId")}
                        {" : "}{get(additionalData, "mangopay_id", "")}</p>
                    <p>{t("accountsTable.detailsModal.iban")}
                        {" : "}{get(additionalData, "iban", "")}</p>
                </div>
                <div>
                    <h4>{t("accountsTable.detailsModal.description")}</h4>
                    <p>{get(additionalData, "description",
                    t("accountsTable.detailsModal.undefined"))}</p>
                </div>
                <div>
                    <h4>{t("accountsTable.detailsModal.raw")}</h4>
                    <code>{jsondata}</code>
                </div>
            </div>
        </Modal>
    );
}

export {
    DetailsModal
}