const mutations = {
  insertSpec: `
    mutation insertOneSpec(
      $data: specs_insert_input!
    ) {
      insert_specs_one(object: $data) {
        id
      }
    }
  `,
  updateSpec: `
    mutation updateSpec(
      $id: Int!,
      $data: specs_set_input!
    ) {
      update_specs(where: {id: {_eq: $id}}, _set: $data) {
        returning {
          id
        }
      }
    }
  `,
  deleteAffectedProducts: `
    mutation deleteAffectedProducts(
      $id: Int!
    ) {
      delete_products_specs(where: {_and: [{id_spec: {_eq: $id}}, {product: {activated: {_eq: false}}}]}) {
        returning {
          id
        }
      }
    }
  `,
  deleteSpec: `
    mutation deleteSpec(
      $id: Int!
    ) {
      delete_specs(where: {id: {_eq: $id}}) {
        returning {
          id
        }
      }
    }
`};

export default mutations;