import { takeLatest, call, select, put } from "redux-saga/effects";
import { FacturationServices } from "../../services";
import { default as FacturationActions, types } from "./actions";
import { showError } from "utils/helpers/notifications";
import moment from "moment";

//exported sagas
function* getSuppliers() {
    yield takeLatest(types.GET_FACTURATION_REQUEST, getFactuSaga);
}


function* getFactuSaga(action) {
    const { token } = yield select((state) => state.auth);
    const [error, response] = yield call(
        FacturationServices.getFacturation, token, action.date_from, action.date_to
    );

    console.log("error : ", error, "response :", response)

    if (error) {
        console.log(error)
        yield put(FacturationActions.getFacturationFailure())
        showError("facturation.error");
    }
    if(response) {
        console.log("resp : ", response)
        const blobFile = new Blob([response?.data], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blobFile);
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.href = url;
        a.download = `export-facturation${moment().format("DD_MM_YYYY")}.csv`;
        a.click();
        window.URL.revokeObjectURL(url);
        yield put(FacturationActions.getFacturationSuccess())
    }
}
export default [
    getSuppliers(),
];
