import { createAction } from "utils/redux";

// Types
export const types = {
    GET_TAGS_REQUEST: "tags_GET_TAGS_REQUEST",
    GET_TAGS_SUCCESS: "tags_GET_TAGS_SUCCESS",
    GET_TAGS_FAILURE: "tags_GET_TAGS_FAILURE",

    UPSERT_TAG_REQUEST: "tags_UPSERT_TAG_REQUEST",
    UPSERT_TAG_SUCCESS: "tags_UPSERT_TAG_SUCCESS",
    UPSERT_TAG_FAILURE: "tags_UPSERT_TAG_FAILURE",

    DELETE_TAG_REQUEST: "tags_DELETE_TAG_REQUEST",
    DELETE_TAG_SUCCESS: "tags_DELETE_TAG_SUCCESS",
    DELETE_TAG_FAILURE: "tags_DELETE_TAG_FAILURE",
};

// Actions
const getTagsRequest = () => createAction(types.GET_TAGS_REQUEST);
const getTagsSuccess = (tags) => createAction(types.GET_TAGS_SUCCESS, { tags });
const getTagsFailure = () => createAction(types.GET_TAGS_FAILURE);

const upsertTagRequest = (tagId, data) => createAction(types.UPSERT_TAG_REQUEST, { tagId, data });
const upsertTagSuccess = (tag, tagId) => createAction(types.UPSERT_TAG_SUCCESS, { tag, tagId });
const upsertTagFailure = () => createAction(types.UPSERT_TAG_FAILURE)

const deleteTagRequest = (tagId) => createAction(types.DELETE_TAG_REQUEST, { tagId });
const deleteTagSuccess = () => createAction(types.DELETE_TAG_SUCCESS);
const deleteTagFailure = () => createAction(types.DELETE_TAG_FAILURE);

const actions = {
    getTagsRequest,
    getTagsSuccess,
    getTagsFailure,

    upsertTagRequest,
    upsertTagSuccess,
    upsertTagFailure,

    deleteTagRequest,
    deleteTagSuccess,
    deleteTagFailure
}

export default actions;