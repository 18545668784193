import { get, set } from "lodash";
import { types } from "./actions";

const initialState = {
    tags: [],
    loading: false
};

function tagsReducer(state, action) {
    if (typeof state === "undefined") return initialState;

    const type = get(action, "type", "");
    switch (type) {
        case types.GET_TAGS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.GET_TAGS_SUCCESS: {
            const tags = get(action, "tags", {});
            return {
                ...state,
                tags: tags,
                loading: false,
            };
        };
        case types.GET_TAGS_FAILURE:
            return {
                ...state,
                tags : [],
                loading: false,
            };
        case types.UPSERT_TAG_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.UPSERT_TAG_SUCCESS: {
            const tags = [...state.tags]  
            const idUpdatedTag = get(action, "tagId", null);
            const newTag = get(action, "tag", {});
            const indexUpdated = tags.findIndex((tag => tag.id === idUpdatedTag));

            if (indexUpdated !== -1) {
                set(newTag, "id", idUpdatedTag);
                set(tags, [indexUpdated], newTag);
            } else {
                set(newTag, "id", idUpdatedTag);
                tags.push(newTag);
            }
            return {
                ...state,
                loading: false,
                tags: tags
            };
        }
        case types.DELETE_TAG_REQUEST:
            const tags = [...state.tags]  
            const idRemovedTag = get(action, "tagId", null);
            const indexRemoved = tags.findIndex((tag => tag.id === idRemovedTag));
            if (idRemovedTag && indexRemoved !== -1)
                tags.splice(indexRemoved, 1);
            return {
                ...state,
                loading: true,
                tags: tags,
            };
        case types.DELETE_TAG_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case "":
        default:
            return state;
    }
}

export default tagsReducer;
