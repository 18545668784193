import { createAction } from "../../utils/redux-helper";

export const types = {
    GET_CURRENT_ON_LOGIN_REQUEST: "user_GET_CURRENT_ON_LOGIN_REQUEST",
    RESET_REDUCERS: "user_RESET_REDUCERS"
};

export default {
    getCurrentLoginRequest: (redirectToHome = false, userType = "") =>
        createAction(types.GET_CURRENT_ON_LOGIN_REQUEST, {
            redirectToHome,
            userType
        }),
    resetReducers: () => createAction(types.RESET_REDUCERS)
};
