import { get, set } from "lodash";
import { types } from "./actions";

const initialState = {
    suppliers: {},
    loading: false,
    count: 0,
    products: [],
};

function suppliersReducer(state, action) {
    if (typeof state === "undefined") return initialState;

    const type = get(action, "type", "");
    switch (type) {
        case types.GET_SUPPLIERS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.GET_SUPPLIERS_SUCCESS:
            const suppliers = get(action, "suppliers", {});
            let count = get(action, "count", -1);

            if (count < 0) {
                count = Object.keys(suppliers).length;
            }
            return {
                ...state,
                suppliers,
                loading: false,
                count: count,
            };
        case types.GET_SUPPLIERS_FAILURE:
            return {
                ...state,
                suppliers: [],
                loading: false,
                count: 0,
            };
        case types.VALIDATE_PAYMENT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.VALIDATE_PAYMENT_SUCCESS: {
            const suppliers = [...state.suppliers];
            const supplierKey = get(action, "key", "");
            const supplierIdx = suppliers.findIndex(
                (item) => item?.id === supplierKey
            );
            set(
                suppliers,
                [supplierIdx, "users", 0, "payment", "account_payment_status"],
                "verified"
            );

            return {
                ...state,
                loading: false,
                suppliers: suppliers,
            };
        }
        case types.VALIDATE_PAYMENT_FAILURE:
            return {
                ...state,
                loading: false,
            };
        case types.SET_STATUS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.SET_STATUS_SUCCESS: {
            const suppliers = [...state.suppliers];
            const key = get(action, "key", "");
            const supplierIdx = suppliers.findIndex((item) => item?.id === key);
            const status = get(action, "status", false);
            set(suppliers, [supplierIdx, "users", 0, "validated"], status);
            return {
                ...state,
                loading: false,
                suppliers: suppliers,
            };
        }
        case types.SET_STATUS_FAILURE: {
            const suppliers = [...state.suppliers];
            const key = get(action, "key", "");
            const supplierIdx = suppliers.findIndex((item) => item?.id === key);
            const status = get(action, "status", false);
            set(suppliers, [supplierIdx, "users", 0, "validated"], !status);

            return {
                ...state,
                loading: false,
                suppliers: suppliers,
            };
        }
        case types.EXPORT_PRODUCTS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.EXPORT_PRODUCTS_SUCCESS:
            const products = get(action, "products", []);

            return {
                ...state,
                loading: false,
                products: products,
            };
        case types.EXPORT_PRODUCTS_CLEAR:
            return {
                ...state,
                loading: false,
                products: [],
            };
        default:
            return state;
    }
}

export default suppliersReducer;
